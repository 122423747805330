import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/final-exam-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1981 slasher movie, Final Exam"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 07</h1>
                    <h2>Final Exam</h2>
                    <h3>May 15, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/final-exam">Back to the episode details</Link></p>
                        <div>
                            <p>Bryan White (00:10.552)
                            You&#39;re listening to the Brothers Grimm Podcast, presented by the Cinema Suicide Film Society on Substack. I&#39;m Brian White, editor in grief of Cinema Suicide. I&#39;m joined by my cohost and actual brother, Dave White. Dave, how you doing? Hey. Whoa, shit. Do you have any delightful novelty beers this time?</p>
                            <p>Dave (00:22.056)
                            Hey!</p>
                            <p>Dave (00:29.614)
                            see what we got here. This is a, no, this is the Rose Hill. It is a barn beer from Plan B in Hudson Valley, New York. I did get this in Hudson Valley, New York. I went there for beer and I came back with beer.</p>
                            <p>Bryan White (00:31.628)
                            The pickle beer did not go well last time.</p>
                            <p>Bryan White (00:42.58)
                            Oh, did you get that while you were in Hudson Valley, New York?</p>
                            <p>Bryan White (00:50.628)
                            Mission accomplished. So we practically grew up in neighborhood video stores, steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies and Dave. Before we get into it, here&#39;s a little housekeeping. If you want to keep up with us between episodes, you can also find us on Twitter and Instagram at GrimBrosPod, and we have full episode transcripts on our website BrothersGrimPodcast.Show.</p>
                            <p>Bryan White (01:17.776)
                            You can also contact us directly at grimbrospod at gmail.com with any questions, comments, or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give it the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts and you&#39;d be doing us a favor by leaving us a five-star review on Apple podcasts. Just want to get that all out of the way right at the top of the show. If you don&#39;t like what you hear, do you know what I have to say? You don&#39;t like what you hear? Suck it!</p>
                            <p>Dave (01:31.822)
                            Okay.</p>
                            <p>Dave (01:40.719)
                            And if you don&#39;t like what you hear, you wouldn&#39;t be the first person.</p>
                            <p>Bryan White (01:47.48)
                            That&#39;s what I say. I have opinions. I have opinions. All right, so warning before we get rolling, we&#39;re going to talk about this movie from beginning to end. So spoilers to follow. You&#39;ve had a million years to watch this one. So let me give you a taste. I&#39;m going to give you a little taste here.</p>
                            <p>Dave (01:47.582)
                            Yep. I have opinions and that is a thing about me.</p>
                            <p>Bryan White (03:41.392)
                            Final exam. He&#39;s come back. He&#39;s come back. There&#39;s a lot of shit going on in that trailer and I got questions. First of all, it sounds... They&#39;ve not seen the movie clearly. Also, are they trying to sell it like it&#39;s a comedy?</p>
                            <p>Dave (03:43.33)
                            He&#39;s come back. What the fuck just?</p>
                            <p>Dave (03:50.218)
                            Yeah, whoever made that trailer has not seen the movie.</p>
                            <p>Dave (03:58.213)
                            I have a thing about that.</p>
                            <p>Bryan White (03:59.344)
                            Cause I&#39;m gonna say the first like 15 seconds of that, of that is like, almost like they&#39;re trying to sell us student bodies. Like, hey, look at all this wacky shit that happens.</p>
                            <p>Dave (04:07.174)
                            Okay, here&#39;s my thing about that. I&#39;m pretty sure this movie is trying to walk a line between two kind of newly emerging genres, which is the sexy college comedy and the slasher. And I will give it credit, first of all, it&#39;s neither of those things. It fails at both.</p>
                            <p>Bryan White (04:28.108)
                            Yeah, I&#39;ve got some notes on that, because it has a fundamental misunderstanding of what makes either of those sort of niches work. And we&#39;ll cover that adequately.</p>
                            <p>Dave (04:42.69)
                            But I will say that it is adequate. Well, it&#39;s admirable in the fact that this is 1981. And so they&#39;re really trying, they&#39;re doing a thing that has not yet been done a lot.</p>
                            <p>Bryan White (05:01.576)
                            Yeah, yeah, okay. I&#39;ll give it that I&#39;ll give it that because it does seem like it&#39;s Like prom nights the movie that really kind of kicks off the like school slasher crossover But that movie isn&#39;t trying to ride the line. It&#39;s it&#39;s it just that prom night is what it is Like this is very clearly Yep, so Yeah, oh god, we&#39;re gonna get into it because this is this was a real challenging one</p>
                            <p>Dave (05:03.147)
                            And that&#39;s a baseline, like that&#39;s at best.</p>
                            <p>Dave (05:21.166)
                            And I almost chose Prom Night because I love that movie.</p>
                            <p>Bryan White (05:31.352)
                            to do. So some facts about it. We&#39;re back in 1981. So this was made. I don&#39;t know when it was released, but this was the same year that Porky&#39;s came out. And Porky&#39;s is really the movie that, even though Animal House is the kind of flagship college comedy, there&#39;s a lot of the sort of like teen sex tropes that just kind of got copied a million times, Porky&#39;s is the one that really kind of put the flag on the map.</p>
                            <p>Bryan White (06:01.252)
                            Bob Clark, who, this guy, that guy is just like one of the most nameless directors and he&#39;s made so many fucking awesome movies.</p>
                            <p>Dave (06:02.818)
                            Hell yeah. Yeah, the dude made my favorite movie of all time.</p>
                            <p>Bryan White (06:12.16)
                            Yeah, which I believe we&#39;re doing later this year. Yeah, but don&#39;t say what it is. Let&#39;s tease them, keep that one a secret. But so. It&#39;s baby James. Ha ha ha ha.</p>
                            <p>Dave (06:15.182)
                            That&#39;s we are.</p>
                            <p>Dave (06:20.791)
                            It&#39;s baby geniuses. Which I&#39;m not joking, Bob Clark did in fact make that movie.</p>
                            <p>Bryan White (06:27.552)
                            Yep. Yep. So some other movies released that year, Dark Knight of the Scarecrow, Cannibal Ferox. Is it?</p>
                            <p>Dave (06:34.198)
                            That is a television film. Dark Knight and Scarecrow, yeah.</p>
                            <p>Bryan White (06:39.388)
                            I had no idea because I believe I&#39;ve ever known that one from just like seeing the really awesome box on the video store shelf You know So cannibal pharax also that year umberto lindsey Nope nope that is one of the most one of the roughest cannibal movies I actually think that one&#39;s a little more hardcore than cannibal holocaust Also the monster club Evil speak</p>
                            <p>Dave (06:49.89)
                            Definitely not a television film.</p>
                            <p>Bryan White (07:07.716)
                            Bloody Moon, the Jesus Franco movie. No, man, these are real movies. You&#39;ve seen The Monster Club.</p>
                            <p>Dave (07:10.69)
                            Are you making this up?</p>
                            <p>Dave (07:17.423)
                            I have seen the Monster Club. It&#39;s not good.</p>
                            <p>Bryan White (07:19.244)
                            You&#39;ve seen the evil, I love the monster club by the way. You&#39;ve seen evil speak. I know John Carradine, another Grimbrows favorite is in that movie. You&#39;ve seen evil speak.</p>
                            <p>Dave (07:22.247)
                            Even Vincent Price can&#39;t save that.</p>
                            <p>Bryan White (07:33.784)
                            It&#39;s the haunted Satan computer one with Clint Howard. Oh, yes. Yep, Bloody Moon is Jess Franco&#39;s really, really weird attempt to kind of remake Halloween from Spain. And it also meant he does not understand the concept of the final girl whatsoever. Lastly, graduation day.</p>
                            <p>Dave (07:38.712)
                            Oh yes.</p>
                            <p>Bryan White (07:59.116)
                            So cast and crew is gonna be real anemic. Usually I have a lot to say about this sort of stuff. So,</p>
                            <p>Dave (08:04.422)
                            Here&#39;s a movie made by and starring people you&#39;ve never heard of.</p>
                            <p>Bryan White (08:07.984)
                            That&#39;s the thing, so this, I don&#39;t know how you say his last name, it&#39;s Jimmy, it&#39;s either Houston or Huston. He hasn&#39;t done anything really worth talking about. I think he directed like six films in total. He directed something called Cebo, which sounds like a Troy McClure movie. And his most notable credit is a writing credit on the Buddy Cop flick Running Scared. That&#39;s the Billy Crystal and Gregory Hines one.</p>
                            <p>Dave (08:13.518)
                            I think it&#39;s Houston.</p>
                            <p>Dave (08:32.554)
                            Wait, didn&#39;t he direct my best friend as a vampire?</p>
                            <p>Bryan White (08:35.808)
                            Uh, I don&#39;t think so. Or maybe he did and I-</p>
                            <p>Bryan White (08:40.132)
                            but running scared.</p>
                            <p>Dave (08:41.11)
                            Which is a weird gay allegory.</p>
                            <p>Dave (08:45.558)
                            It is.</p>
                            <p>Bryan White (08:45.852)
                            Well, well again, I mean, there&#39;s a little bit of that in here too, so I guess it&#39;s just a running thing. The cast, the lead is a woman named Cecile Bagdadi who plays the world&#39;s most forgettable final girl Courtney, and Joel Rice who plays Radish, who is the only character in the movie worth talking about, and I have a feeling we&#39;re going to talk about him a lot. His performance in this movie is fucking mesmerizing.</p>
                            <p>Bryan White (09:14.724)
                            He only acted in a few pictures that nobody has seen. He has a credit as an extra in Christine, but he very quickly bailed on acting in the 80s and he ended up producing Hallmark pictures after a brief stint as a social worker. And he&#39;s still working. He&#39;s still making these movies. Like if you&#39;re into that whole scene, I am not. I don&#39;t understand the appeal of them. So you have probably seen at some point Joel S. Rice productions.</p>
                            <p>Dave (09:37.752)
                            Oh, I am.</p>
                            <p>Bryan White (09:47.841)
                            Yep. Also, like a weird note, usually when they roll out these movies and they kick off the title sequence and the credits, there&#39;s a lot of like starring so-and-so and so-and-so, and also so-and-so, featuring so-and-so, introducing so-and-so. This movie has two screen credits, and they&#39;re the two people that I just mentioned.</p>
                            <p>Dave (09:59.645)
                            Introducing.</p>
                            <p>Dave (10:05.55)
                            And they are both introducing.</p>
                            <p>Bryan White (10:07.896)
                            Yep, I believe these are both of their first movies. Or either that or both of them had done like extras on TV or something, but this was their first real sort of like major, major thing. So this movie, let&#39;s see, $363,000 was wasted on the making of this movie, but it did turn a profit about a million dollars. And I&#39;m sure that like collectors have picked up basically everything that&#39;s been released ever since then. Like this is one of those movies that to me kind of says,</p>
                            <p>Bryan White (10:38.904)
                            If like Screen Factory is releasing these these sorts of things that coming out on like blu-ray and like 4k and shit like that Like we are truly scraping the bottom of the barrel in terms of like what&#39;s left to be discovered and released</p>
                            <p>Dave (10:52.091)
                            I think this just has a 1080 release. There&#39;s no 4k of this. Even vinegar syndrome won&#39;t invest in the 4k of this. They will. They really will. God bless them.</p>
                            <p>Bryan White (10:55.488)
                            Really? Okay.</p>
                            <p>Bryan White (11:01.176)
                            They&#39;ll release anything Yeah, and so also yeah, they&#39;re doing the Lord&#39;s work with their pornography and horror movies And okay, so proving that no film of the time was beyond novelization there is a final exam novel I know that this this is like this must have landed in that like golden era We&#39;re just like and they could find an author to novelize anything</p>
                            <p>Dave (11:10.35)
                            That&#39;s, yup. Fuck yes.</p>
                            <p>Bryan White (11:30.58)
                            I gotta find it. Because apparently there&#39;s a whole lot, like the author looked at the script and was like, this is fucking bullshit, and filled in a lot of blanks. This movie currently holds a four skull rating on Shudder, which is fucking inexplicable to me. Out of five. And so after I watched it the first time, I added my own review, which is just one sentence demanding that everybody giving it five skulls explain themselves.</p>
                            <p>Dave (11:44.516)
                            Wait, out of five?</p>
                            <p>Dave (11:57.23)
                            I feel like that&#39;s when you go on like the PlayStation or Xbox store and you see anything that&#39;s less than four and it&#39;s like, wow, this must really suck.</p>
                            <p>Bryan White (12:08.829)
                            So overall this movie this movie is fucking terrible It&#39;s it really if you watch it It&#39;s it this is like real like for completists only territory that we&#39;re it It&#39;s like a lot of the C tier cash is at a time where casts are really wide net It&#39;s kind of what we were talking about just a few minutes ago where we&#39;re trying to exploit several popular genres at a time so besides</p>
                            <p>Bryan White (12:32.132)
                            cashing in on the slasher trend, it&#39;s also cashing in on Porky&#39;s and the sex comedies that were kind of emerging at the same time. Except that where those all seem to come out, where all of these kind of proper much more well-known like screwballs and you know like that that sort of thing. This one was released the same year and I think it might have actually been released earlier than Porky&#39;s was because that movie I looked that one up just when I was kind of writing this note. Porky&#39;s came out really late in 1981.</p>
                            <p>Bryan White (13:03.172)
                            But really, Animal House is the very obvious comparison because there&#39;s even a character. I mean, all of these movies needed a Bluedo, but there&#39;s a character in this one.</p>
                            <p>Bryan White (13:14.188)
                            thing that really kind of where it really fails is it doesn&#39;t really get any of the slasher tropes right it doesn&#39;t get any of the sex comedy parts right it&#39;s just 90 minutes of filler I think it comes in almost at 90 minutes on the dot which seems very formulaic to me</p>
                            <p>Dave (13:31.383)
                            Yeah, it is barely a movie.</p>
                            <p>Bryan White (13:33.808)
                            It&#39;s just scenes strung together, loosely forming the plot of a movie. And as we go through them, like we&#39;re about to do, you&#39;re gonna see this movie is just a disaster. So I figured I&#39;d throw this out before we really get into it. Here&#39;s a few horror movies with the word final in the title that you should probably watch instead. Friday the 13th, the final chapter.</p>
                            <p>Bryan White (14:03.492)
                            final destination and the final terror which is great have you ever seen the final terror no no the final terror is the one that&#39;s got like uh it&#39;s the one where a bunch of like forestry service people get like stuck stuck in the forest got like joe panelliano in it darryl darryl hannah&#39;s in it it&#39;s</p>
                            <p>Dave (14:05.089)
                            Yeah. Yeah. A space movie?</p>
                            <p>Dave (14:23.518)
                            And they&#39;re all it&#39;s it&#39;s like just turns out at the end. It&#39;s just like some random psycho who&#39;s like a little kid at one point.</p>
                            <p>Bryan White (14:30.216)
                            No, no, I don&#39;t know what you&#39;re thinking of but this is the one where They&#39;re constantly on Joe Panigliano&#39;s characters ass about because he&#39;s a real hard ass in a pain in a piece of shit it turns out that his mother is like is like this camouflage survivalist maniac who like Just stalks the forest and she&#39;s like trying to kill him has a real low body count But it is super fucking tense like it is</p>
                            <p>Dave (14:43.688)
                            Yeah. Yeah.</p>
                            <p>Dave (14:55.406)
                            And I think it must have come out around the same time just before Dawn came out, because it&#39;s very similar.</p>
                            <p>Bryan White (14:59.136)
                            Yes, I think it was released at the time that just before Dawn came out because the movie was made in 81 but wasn&#39;t released until 83.</p>
                            <p>Dave (15:07.502)
                            Because Just Before Dawn has, if you&#39;ve never seen it, it has the greatest ending of any horror movie ever made.</p>
                            <p>Bryan White (15:15.025)
                            I&#39;ve not actually seen it, so I&#39;m going to have to put that one on my list. Because the.</p>
                            <p>Dave (15:17.646)
                            I&#39;m not gonna ruin it because holy shit, the ending of that movie is nuts.</p>
                            <p>Bryan White (15:21.904)
                            I&#39;ll say because the final Terror&#39;s ending is like one of the only bad things I can say about it. It&#39;s a bit of a dud but uh, Yeah It&#39;s it&#39;s super super good. It&#39;s on shutter final exams also on shutter if you if you listen to this you feel like watching it Uh, so as I mentioned, uh two acting credits in the title blah blah blah But let&#39;s get into it. Let&#39;s just jump in so the movie starts out As so many slasher movies do we got to sort of plant a flag that this is a slasher movie</p>
                            <p>Bryan White (15:52.468)
                            And it&#39;s not at Lanier College, which is where this is shot, where the movie takes place. It&#39;s at March College, which is, I don&#39;t know, it&#39;s a campus nearby, I guess. But this was made in North Carolina and South Carolina. It is, and there&#39;s a couple of characters who are just like, they must have been local actors or something. But yeah, so two kids in the car making out.</p>
                            <p>Dave (16:02.809)
                            They&#39;re in the south, right?</p>
                            <p>Dave (16:09.779)
                            Is it a Southern as fuck?</p>
                            <p>Bryan White (16:23.588)
                            The guy is very insistent, and the girl is very apprehensive. And he&#39;s like all over her, and she pulls away, and she&#39;s like, no. And for me, this is a very forward-thinking movie, because she says something like, he&#39;s like, what&#39;s the problem, baby? And she&#39;s like, I don&#39;t have to have a reason. And I&#39;m like, fuck yeah, man. No means no. Drive that poor girl home, because this is bullshit. But again.</p>
                            <p>Bryan White (16:53.268)
                            They gotta kill these two, so he kinda keeps on her. And, um... Oh, but before that, she&#39;s like, Ah, get it off of me, what if I wanna watch the ducks swim? And he says the most fucking insane thing. He says, ducks don&#39;t swim at night, their headlights short-circuit in the water.</p>
                            <p>Dave (16:57.112)
                            And then she says, I love you.</p>
                            <p>Dave (17:13.614)
                            What?</p>
                            <p>Bryan White (17:14.392)
                            Is that a fucking joke that I don&#39;t get or something? Like, what is that? Did he like, did he improv that? Like, is this ad libbing? Because, yeah, it&#39;s, I just, I had to point that out because what the fuck? But like, again, as we&#39;re gonna find out, this movie&#39;s got a ton of filler in it. And it is insufferable at times. And like this movie just started and it&#39;s already insufferable. This,</p>
                            <p>Dave (17:21.837)
                            I think this whole movie is ad-libbing, but yes.</p>
                            <p>Dave (17:42.378)
                            Yeah, there&#39;s lots of walks down like slow walks down hallways.</p>
                            <p>Bryan White (17:46.208)
                            Yep, this whole scene really kind of stuck out to me and struck me as, this is like the Sabini scene in Maniac, except it sucks and the quarterback sucks. At one point, she does the whole I love you thing and she says it a couple of times and he&#39;s like, you know, like tugging on his collar and shit, like trying not to say it and eventually fucking relents. But.</p>
                            <p>Dave (18:05.706)
                            I know.</p>
                            <p>Dave (18:10.166)
                            Yeah, nothing says I love you like a panicked look and a long pause.</p>
                            <p>Bryan White (18:16.001)
                            Yeah, yep. I mean this guy must have something going on to keep her to keep her in the place because like he&#39;s I don&#39;t I don&#39;t get it I don&#39;t get the appeal of any of these dudes. That&#39;s, that&#39;s gotta be it and his car is very fancy. But she is what does he says, oh, oh she says, or maybe there. No she says, there isn&#39;t another girl on earth who&#39;d put up with you.</p>
                            <p>Dave (18:25.088)
                            He&#39;s the quarterback, man.</p>
                            <p>Bryan White (18:43.052)
                            And I thought, lady, you shouldn&#39;t either. This guy&#39;s a fucking dud. Like nothing about this. Yeah, you know what? You deserve what&#39;s about to happen. But yeah, just like in Maniac, there&#39;s a lot of like, oh, I think there&#39;s somebody out there. And like eventually the car starts shaking and the guy like jumps up on the hood of the car.</p>
                            <p>Dave (18:46.754)
                            Fuck the both of you.</p>
                            <p>Dave (19:02.414)
                            Which I, this part drove me nuts because it is, so the scene in Maniac where he jumps up on the hood, like if that fucking fat slop can jump up on the hood of that car and make that scene tense and cool as hell, there is no reason why this dude has to like clamber up onto the car slow as hell.</p>
                            <p>Bryan White (19:15.224)
                            cool as hell. Sure.</p>
                            <p>Bryan White (19:20.34)
                            He struggles which is crazy because we made it a point to really kind of nail it down that like Joe spinel does not have the cardio to be a Yeah, so yeah, this is the thing like so the killer jumps up on the hood and like he&#39;s got a knife And so he cuts it to rag top</p>
                            <p>Dave (19:30.828)
                            That man&#39;s Mitchell. He spends the whole movie sweating.</p>
                            <p>Bryan White (19:44.012)
                            And so he cuts across the rag top with what appears to be the sharpest fucking knife I&#39;ve ever seen. Like this thing must have been</p>
                            <p>Dave (19:48.946)
                            And even still, the dude in the car is just like, I&#39;m going to, oh, you guys, I&#39;m going to get you. And it&#39;s like, wait, do you not know what&#39;s happening?</p>
                            <p>Bryan White (19:54.168)
                            Yeah, he just Yeah, they&#39;re in the back seat and he jumps into the front seat and instead of just like, I don&#39;t know stabbing the kid in the head or something like that the killer keeps like reaching in and trying to like undo the clamps and stuff like so he could pull the top off because you see him like struggling and this gives the kid, the quarterback, enough time to start the car and start to drive when eventually the guy like reaches down and grabs him and you know he stabs him on the hood of the car</p>
                            <p>Bryan White (20:22.368)
                            And then we, we, the camera sort of like moves in on the girl who does these very measured screams before presumably the killer kills her. And that&#39;s when we get our little title sequence that goes.</p>
                            <p>Dave (20:35.107)
                            Yeah, this is very much like that scene in pieces, the bastard scene where it&#39;s just like long shriek pause, long shriek pause.</p>
                            <p>Bryan White (20:39.02)
                            Oh my god pause yep so what I found because there&#39;s a couple there&#39;s a couple of spots where you hear a scream but the girl is very clearly not screaming and apparently what what happened was when the the actress who is Courtney the lead went in like they were like hey why don&#39;t you give us some screams since you&#39;re going to be a scream queen or something like you know whatever</p>
                            <p>Bryan White (21:06.772)
                            auditions and so she did like a shitload of them and they recorded them and then they just repurposed them throughout the movie so you just whatever girl screams like you&#39;re hearing her scream they were so impressed with her ability to scream so uh after this like after the credits roll we we move into a very active campus in the american south</p>
                            <p>Dave (21:31.862)
                            and the most amazing incidental music I&#39;ve ever heard. This is cut back from commercial music.</p>
                            <p>Bryan White (21:35.152)
                            that okay I have a note I have a note about that so she is studying in the upper levels of what appears to be a very dangerous condemned building which which again this is Chekhov&#39;s very dangerous condemned building because it</p>
                            <p>Dave (21:50.57)
                            Oh, there&#39;s gonna be checkoffs all up all up on this.</p>
                            <p>Bryan White (21:53.664)
                            I&#39;ve got Chekov&#39;s tray elevator in here as well. You would. Ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha</p>
                            <p>Dave (21:55.97)
                            Fuck you, I do too. God damn it.</p>
                            <p>Dave (22:11.566)
                            Thanks for watching!</p>
                            <p>Dave (22:22.258)
                            It has that every single time this music, it&#39;s like this swell as though we&#39;re either cutting to commercial or coming back from commercial.</p>
                            <p>Bryan White (22:29.877)
                            Yep, it&#39;s fucking... you know the thing is is the cheesy music that like that occupies these spaces, I could totally do without. But there are actually a couple of points where the music I was actually like, okay, this is working. Even though... yeah, yeah. You can buy it on one of those like fluffy, you know, multicolor vinyl editions too. Like it&#39;s available. Oh, yeah, I know how you like your records.</p>
                            <p>Dave (22:44.534)
                            And this movie has a composer. This is not just library music.</p>
                            <p>Bryan White (22:59.252)
                            um So uh, hey, uh, so so yeah the girl comes running down and uh, she is joined by another Shithead dude And I can&#39;t tell now. This is this is mark the like boss of the frat</p>
                            <p>Dave (23:16.118)
                            Yeah, is he supposed to be a protagonist? Cause he&#39;s a real piece of shit. I mean, everyone in this movie is a piece of shit, but he&#39;s well, except for one. Yeah, except for one of them.</p>
                            <p>Bryan White (23:19.684)
                            He&#39;s a fucking asshole, like he is-</p>
                            <p>Bryan White (23:24.352)
                            You leave our radish alone. Yeah. Well, the thing is, I couldn&#39;t tell if he was supposed to be Courtney&#39;s boyfriend or not, because this interaction sort of suggests it.</p>
                            <p>Dave (23:38.25)
                            I feel like he&#39;s supposed to be everybody&#39;s boyfriend.</p>
                            <p>Bryan White (23:40.78)
                            maybe but uh yeah because the thing is is that throughout all of this she&#39;s the chased final girl she never but she never really seems to act romantically toward him and even there&#39;s even a scene like a little further on here where like the kind of like slutty victim to be shows up and he&#39;s like hey when you gonna let me hit that so yeah i don&#39;t know he&#39;s he he&#39;s just a he&#39;s a dick i he</p>
                            <p>Dave (24:06.866)
                            He says he says something in this scene that I think it really sums up his character, but also the 80s In in which she says what do I need chemistry for I&#39;m going into advertising</p>
                            <p>Bryan White (24:19.908)
                            I was trying to work something in here about how this is very reflective or he his character is very reflective of that Reagan period Because that&#39;s really because there&#39;s a lot of that like he&#39;s like the material color popped Mirror shades like 80s cool guy or at least he&#39;s the at least he&#39;s the one that everybody thinks is the cool guy</p>
                            <p>Dave (24:22.111)
                            Yah.</p>
                            <p>Bryan White (24:44.948)
                            I don&#39;t know, he&#39;s such a fucking asshole and his hair sucks, I hate it so fucking much.</p>
                            <p>Dave (24:48.279)
                            Okay, the hair of the hair in this movie. Either they had a huge hair budget or no hair budget because it is it is it dominates the scene. Every frame is 60 percent hair.</p>
                            <p>Bryan White (25:04.602)
                            I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that. I&#39;m just gonna say that.</p>
                            <p>Dave (25:21.974)
                            And it&#39;s effortless, the whole thing is effortless.</p>
                            <p>Bryan White (25:24.312)
                            Yeah, yeah, he&#39;s like Steve on Stranger Things. It&#39;s weird.</p>
                            <p>Dave (25:27.214)
                            This is what they say on random horror generator podcasts. This is what they call the high seventies.</p>
                            <p>Bryan White (25:35.765)
                            Yeah, yeah, this is. I feel like that way about like every sort of change into each decade is like you got a couple of years when you&#39;re, you know, you&#39;re definitely in the new decade, but you&#39;re feeling that the hangover in the last one. And this one definitely feels like that. It&#39;s almost like dazed and confused a bit where like all of the like the teenagers look like adults because they all got.</p>
                            <p>Dave (25:35.854)
                            It&#39;s like, it&#39;s still the early 80s, but it&#39;s still got that aesthetic.</p>
                            <p>Bryan White (26:03.544)
                            big fucking mustaches and stuff.</p>
                            <p>Dave (26:05.566)
                            And I kind of appreciate that, I will be honest. I love a movie where beauty standards were lower. And you&#39;re gonna get this a lot in the 70s and 80s where it&#39;s like some of these people look gnarly as hell. Like they are, there&#39;s acne, they have got fucking eyebrows, like nobody&#39;s business. Like it is, I love that shit.</p>
                            <p>Bryan White (26:13.496)
                            Hahaha.</p>
                            <p>Bryan White (26:19.278)
                            Yeah.</p>
                            <p>Bryan White (26:30.634)
                            Yep. Yep. So there&#39;s a little bit of banter back and forth. And then enter Radish. Thank god. Our.</p>
                            <p>Dave (26:35.174)
                            Oh my-</p>
                            <p>Dave (26:38.026)
                            Radish, from moment one, Radish is doing it for the back rows.</p>
                            <p>Bryan White (26:42.424)
                            He is... I am so grateful for this character because if he were not in this movie, I would have suffered through the whole thing. His performance... He must have... and he&#39;s the one character who seems to have any sort of dimension to him also. Like everybody else is just a character whose destiny is with a knife.</p>
                            <p>Dave (26:54.026)
                            He is baffling, he is compelling, he is everything and nothing.</p>
                            <p>Dave (27:11.574)
                            I mean, those dimensions don&#39;t make a lot of sense, but...</p>
                            <p>Bryan White (27:11.939)
                            uh</p>
                            <p>Bryan White (27:14.688)
                            No, no, but he&#39;s so great. He&#39;s so great. He comes running up into the scene and he is this like nebbish, nerdy, just a American college student. And he comes.</p>
                            <p>Dave (27:28.91)
                            Okay, here&#39;s how I imagine this whole thing played out. The director, and I&#39;m using that very generously, the director said, you&#39;re awkward, you&#39;re nerdy, you don&#39;t know how to act around girls. And he&#39;s like, okay, right. And then he gives us, I&#39;m the lead in Boys in the Band. Because out the gate, and as far as I know, this actor is not gay, but.</p>
                            <p>Bryan White (27:54.752)
                            Okay, I&#39;m glad that you said it because I was a little nervous to break it up because I don&#39;t want to just be like, isn&#39;t this isn&#39;t this isn&#39;t this guy the gayest character you&#39;ve ever seen? Because he he does. He reads gayer to me that he does nerdy.</p>
                            <p>Dave (27:59.794)
                            Oh, allow me!</p>
                            <p>Dave (28:10.41)
                            because I think what they were like, you&#39;re a nerd now go. And he&#39;s like, okay, I&#39;m a nerd. And he comes back as the weirdest gay stereotype. Like the kind, why I say boys in the band because it&#39;s very like 1960s, like he&#39;s sensitive and he&#39;s this, it&#39;s like, what the fuck are you doing?</p>
                            <p>Bryan White (28:31.624)
                            He&#39;s Paul Lin if he were in Revenge of the Nerds.</p>
                            <p>Dave (28:34.114)
                            Yeah, he&#39;s Paul Lind if Paul Lind weren&#39;t an alcoholic. Like he&#39;s Paul Lind if Paul Lind weren&#39;t an angry gay man. And you know what, in the 60s, they were all angry gay men. But like, he just comes out like, just like, oh my God, it&#39;s so nice to be here, everybody.</p>
                            <p>Bryan White (28:45.444)
                            Well, yeah, I mean, that&#39;s understandable.</p>
                            <p>Bryan White (28:55.008)
                            He is practically floating. Yeah, it&#39;s so weird. But he&#39;s.</p>
                            <p>Dave (28:57.142)
                            Yeah, it&#39;s so weird.</p>
                            <p>Dave (29:01.41)
                            Don&#39;t worry, you just leave the homophobia to me, I got this.</p>
                            <p>Bryan White (29:05.792)
                            I just didn&#39;t know how to bring it up. But also, I&#39;m glad that it wasn&#39;t just me as I&#39;m reading this, because I&#39;m like, this couldn&#39;t. I was like, am I being terrible right now? Or is this really how it seems?</p>
                            <p>Dave (29:09.983)
                            It&#39;s okay, because I&#39;m one of them.</p>
                            <p>Dave (29:22.17)
                            He is the reason I wanted to watch this movie because it is so fucking weird. Like the rest of this movie, it&#39;s barely there. It is a non-movie, except for this incredibly strange, compelling, baffling character.</p>
                            <p>Bryan White (29:24.675)
                            Hahaha</p>
                            <p>Bryan White (29:37.12)
                            I feel confident in saying that if you watch this movie, he&#39;s the reason to watch it. And I would say that, I would say watch it because he is just that interesting. There is not another character out in any other movie that I can think of that&#39;s like him. Like... I would say watch it because he is just that interesting. Like there is not another character out in any other movie that I can think of that&#39;s like him. Like...</p>
                            <p>Dave (29:53.322)
                            Yeah, you don&#39;t want to watch this movie like on your own, like on a Tuesday. This is like a, it&#39;s Saturday and I just took an edible kind of movie. Like this is not a thing you invest like real time in.</p>
                            <p>Bryan White (30:01.881)
                            Hahaha</p>
                            <p>Bryan White (30:05.344)
                            He&#39;s, oh, thank God for him. He comes running up and he, first of all, can he not afford a backpack? Because he carries this like massive stack of books for like half the movie. Yep, yep. Yeah. Yep.</p>
                            <p>Dave (30:15.19)
                            Because they want you to know he is studious. He&#39;s the smart kid. And because of that, he is gonna get bullied. Because of that and not his weird, incredibly gay affect.</p>
                            <p>Bryan White (30:27.105)
                            I&#39;m going to tell you what, he really does take the bullying in stride. Because it keeps happening and then he just kind of collects himself and he&#39;s got a smile. He&#39;s unflappable.</p>
                            <p>Dave (30:30.786)
                            Yeah, like he&#39;s just like, oh, you guys.</p>
                            <p>Dave (30:39.454)
                            And yet weirdly, it does not seem to come from a... Actually, it does and doesn&#39;t come from a place of malice. Like they&#39;re just like, ah, Radish is just one of the dudes, but he&#39;s also the guy we rag on all the fucking time.</p>
                            <p>Bryan White (30:47.981)
                            It&#39;s...</p>
                            <p>Bryan White (30:53.52)
                            Yep, yep. But yeah, but he comes running into the scene with this like humongous fucking stack of books. And I believe, I believe that&#39;s a pocket protector in his pocket. And he&#39;s, and he comes, he&#39;s like, oh my God, did you guys hear? Or did you hear what happened at March College or something like that? And Mark, the douchebag frat bro, says like, this was another football recruiting scandal. And he says, better, a mass murder. And apparently it&#39;s awesome.</p>
                            <p>Dave (31:03.347)
                            Oh yes, yes it is.</p>
                            <p>Dave (31:22.546)
                            Now look, I don&#39;t know how you rank these things, but I will be honest, I think a mass murder is more interesting than a football recruiting scandal, because I don&#39;t give a fuck about football. Mass murder though, okay.</p>
                            <p>Bryan White (31:30.772)
                            I&#39;m sorry. I&#39;m sorry.</p>
                            <p>Bryan White (31:33.616)
                            Yep. Nope. But Mark is into this because apparently it&#39;s also, it&#39;s awesome because the QB got killed because now whatever school it is that they&#39;re at, they could beat them. But also, why the fuck is he called a radish? This seems like such a, it seems both random and also very deliberate. Like there&#39;s a- I don&#39;t wanna know. Just let&#39;s let some mysteries lie.</p>
                            <p>Dave (31:55.958)
                            But you know what, I don&#39;t wanna know. I want this to be a mystery, like everything else about him.</p>
                            <p>Bryan White (32:05.132)
                            Yep, but he is like super and what we come to find out is that Radish is like really really obsessed with true crime. Like he&#39;s like one of the original like true crime like he would probably have like six podcasts of his own about true crime today if he were like a real person, but he seems like both morbidly obsessed with them and fascinated but also terrified of them.</p>
                            <p>Dave (32:31.234)
                            Look, I&#39;m not gonna lie, if Radish had a true Grime podcast, I&#39;d listen to it. A lot. I mean, I.</p>
                            <p>Bryan White (32:36.502)
                            I would 100% listen to it. I would be subscribed and I would be in his Patreon.</p>
                            <p>Dave (32:41.33)
                            I do think that the valorization of mass murder in this movie is a very bold choice.</p>
                            <p>Dave (32:54.543)
                            Oh god.</p>
                            <p>Bryan White (33:05.359)
                            the the the the</p>
                            <p>Dave (33:06.894)
                            I wanna say Wildman is among the better actors in this film.</p>
                            <p>Bryan White (33:11.141)
                            I have that on here, his character is annoying as hell, but he&#39;s trying where the others are not.</p>
                            <p>Dave (33:17.234)
                            Oh yeah, he is a cliche through and through, but he really does sell it.</p>
                            <p>Bryan White (33:23.977)
                            Yeah.</p>
                            <p>Dave (33:25.802)
                            It&#39;s like, what if John Belushi&#39;s character weren&#39;t funny and just fucking sucked?</p>
                            <p>Bryan White (33:33.304)
                            Yeah, they&#39;re on their way to chemistry class for their chemistry finals. I don&#39;t know why you need chemistry classes. Neither do I. You think that his curriculum would match his major, right? Uh, was it Mark needs to get an 82 on his test or his parents are gonna... Very specific. He&#39;s done the math for how to get a passing grade, but if he doesn&#39;t, his parents are gonna stop paying for his sweet ride, which is like a f<em>*</em>ing</p>
                            <p>Dave (33:39.316)
                            I don&#39;t know why you need chemistry if you&#39;re going to go into advertising.</p>
                            <p>Dave (33:52.558)
                            Which is oddly specific.</p>
                            <p>Bryan White (34:03.136)
                            a firebird or something like that we see it eventually. So then we move into the classroom for a teacher who is a fucking dead ringer for Kurt Vonnegut but where Kurt Vonnegut but where Kurt Vonnegut is cool this guy fucking blows and it is it is criminal that he doesn&#39;t get killed in this movie.</p>
                            <p>Dave (34:15.394)
                            Yeah, right?</p>
                            <p>Dave (34:19.682)
                            Yeah, no, he&#39;s fucking worst.</p>
                            <p>Dave (34:26.102)
                            But this is where we get more of that valorization of mass murder where it&#39;s just like, Oh, Charles Whitman, he&#39;s one of my favorites. That&#39;s a goddamn quote.</p>
                            <p>Bryan White (34:35.662)
                            Yeah, because this teacher is like really, and I mean like acutely paranoid about cheating. Like he stands in front of the class as they get ready to take this test and spends like 10 minutes really drilling it into these kids that he&#39;s not going to tolerate cheating. And the thing that kind of leads to the Charles Whitman</p>
                            <p>Bryan White (35:00.86)
                            riffing is that he&#39;s got like a sniper on a nearby rooftop like ready to shoot somebody if they look on somebody else&#39;s paper.</p>
                            <p>Dave (35:07.948)
                            Yeah, for those who don&#39;t know, Charles Whitman was the brain-injured student at Texas A&amp;M in the late 60s who climbed up into a tower and shot a shitload of people and terrorized the entire campus. It is a harrowing story. Now in this movie, though, it is a solid bit of comedy.</p>
                            <p>Bryan White (35:20.405)
                            A shitload of people.</p>
                            <p>Bryan White (35:25.536)
                            It&#39;s a very fucked up story. Like I don&#39;t know. Oh yeah. Well, but you know why? Because what happens fucking next? School shooting.</p>
                            <p>Dave (35:37.982)
                            Oh, wait, we didn&#39;t get, because we got more in between this. You get the coach, you get the introduction of the coach. And I&#39;m gonna, I&#39;m gonna tell you something. The coach is a dildo.</p>
                            <p>Bryan White (35:43.57)
                            Oh God, you&#39;re right. I thought the coach happened afterwards.</p>
                            <p>Bryan White (35:50.5)
                            I fucking hate him so much. I cannot fucking stand him. So he&#39;s gotta be, he&#39;s gotta be. He is like a character out of fucking Duke&#39;s of Hazard or something like, he&#39;s just, he&#39;s ridiculous. So yeah, what is it?</p>
                            <p>Dave (35:54.923)
                            He is a local hire actor.</p>
                            <p>Dave (36:04.27)
                            It&#39;s like, hey, you need someone who&#39;s like a Southern guy who&#39;s like middle-aged and probably cool with rape? I got your dude.</p>
                            <p>Bryan White (36:11.84)
                            I have a fucking note on here that he has probably, he has definitely covered up six sex crimes in the last year alone. Like, I, God, I fucking hate this character so much. And again, I wish he got killed. Cause there&#39;s a couple of these side characters, these shitty side, wait, no he doesn&#39;t.</p>
                            <p>Dave (36:26.611)
                            It does.</p>
                            <p>Dave (36:30.446)
                            Coach, yes he does, he dies at the end.</p>
                            <p>Bryan White (36:34.488)
                            Is that the coach or is that the sheriff?</p>
                            <p>Dave (36:37.135)
                            The sheriff never comes back. The sheriff is also a dildo, by the way.</p>
                            <p>Bryan White (36:40.24)
                            Oh, see, I confused the two characters because they&#39;re basically the same fucking character. And they look alike. Okay, okay, well, this is gonna, my notes are all fucked up in the end then.</p>
                            <p>Dave (36:43.146)
                            Because they&#39;re the same character.</p>
                            <p>Dave (36:47.882)
                            Yeah, like overweight middle-aged guy who&#39;s probably cool with rape.</p>
                            <p>Bryan White (36:52.8)
                            Yeah, he uh, god he sucks, but yeah, so so what was it? Rattich finishes his test uh first and then Wildman finishes his test and he leaves and as he&#39;s leaving like there&#39;s all the while that this is happening there&#39;s been these these kind of like shots of a van moving around the campus and it&#39;s you know the the signal here is the killer has has entered the has entered the scene and so you see Wildman like walking across the quad</p>
                            <p>Bryan White (37:21.324)
                            and he&#39;s about to get killed by, you know, be attacked by the killer, except that it&#39;s not the killer, it&#39;s the stupid fucking coach. And I don&#39;t even really know what the purpose of the scene is. I don&#39;t know what the purpose of any of these scenes is really, but...</p>
                            <p>Dave (37:35.498)
                            to get you from point A to point B until you get to the end.</p>
                            <p>Bryan White (37:37.896)
                            really like to fill out time or something like that because like this again this is like okay so he knows what</p>
                            <p>Dave (37:45.078)
                            These are all just long walks down a hallway to answer the phone. This is filling out 90 minutes.</p>
                            <p>Bryan White (37:48.732)
                            Yeah.</p>
                            <p>Bryan White (37:52.509)
                            He kind of sneaks up and tackles Wildman from behind. And this is when we finally meet him and you really kind of get a sense for what we&#39;re talking about. He just is shitty. Like he&#39;s not a bad, it&#39;s not like he&#39;s an asshole or anything like that. He&#39;s just dumb.</p>
                            <p>Dave (38:07.754)
                            Also, can you imagine like if you ever had a coach who just like did that to you, just came up behind, like first of all, I can&#39;t get off of me, don&#39;t touch me.</p>
                            <p>Bryan White (38:16.698)
                            I would have punched him in the fucking face like just out of like reaction like I would have turned around It just snapped him right in the right in the nose</p>
                            <p>Dave (38:23.862)
                            Well, is this supposed to be like boys being boys? I don&#39;t understand any of this.</p>
                            <p>Bryan White (38:27.844)
                            That&#39;s his whole thing is because he comes back later and sort of excuses everything is boys will be boys, which makes me feel like, you know, pressuring women into the non-consensual sex is also probably just boys being boys and why have the police involved? You know, that sort of thing. But yeah, Yeah, right. But now another now there&#39;s another van driving around and like maybe it&#39;s the killer&#39;s van. We don&#39;t know.</p>
                            <p>Dave (38:46.83)
                            And that&#39;s true. America for the win.</p>
                            <p>Bryan White (38:55.94)
                            but there&#39;s guys with guns inside and uh... not the killer&#39;s van because it&#39;s brown or something like that the other killer&#39;s van is like blue maybe but uh... yeah this is the they they pull up very suddenly very fast the door throws over all these guys in ski masks and fucking M16s and they just start shooting people all over the place and it&#39;s just mayhem on the quad all of a sudden uh... it&#39;s it&#39;s this is a very very long scene where</p>
                            <p>Dave (38:58.45)
                            Also, not the killer&#39;s van.</p>
                            <p>Dave (39:24.143)
                            And so many of the people are just like, the students are just really blase about this school shooting. Like some of them are just like real chill about it.</p>
                            <p>Bryan White (39:31.589)
                            I don&#39;t Yeah, they a bunch of them get shot And then and then it once once it all wraps up like the guys in the ski mask I run out and grab a bunch of the people who&#39;ve been shot and throw them in the van and they drive they drive away And everybody is You know, obviously very very scared about it Except for a few of them who think it&#39;s hilarious</p>
                            <p>Bryan White (39:56.458)
                            And this is the...</p>
                            <p>Dave (39:56.794)
                            Yeah, this is that classic school shooting prank.</p>
                            <p>Bryan White (40:00.06)
                            Oh yeah, you know, it&#39;s never not funny, you know? But this is the first time that we see Radish run, and he has the fucking weirdest run. He, first of all, he&#39;s still holding that huge stack of books, but he&#39;s like flying across the quad. Like he runs really fast. And he calls the, he gets on the phone and he calls. The psychopaths are here.</p>
                            <p>Dave (40:21.402)
                            And he says, and I quote, You have to get out here. It&#39;s happening. The psychopaths are here.</p>
                            <p>Bryan White (40:31.737)
                            But he delivers it in a very impassioned, like, you have to get over here. It&#39;s happening. The psychopaths are here!</p>
                            <p>Dave (40:38.582)
                            Yeah, he sells, like, he is the weirdest fucking character ever committed to film, and he is selling it every minute.</p>
                            <p>Bryan White (40:43.78)
                            Hahaha</p>
                            <p>Bryan White (40:46.676)
                            Yep, it&#39;s such a great line. And then, yeah, we come back to the quad and like, there is this girl who comes running up and she is she sees Courtney and one of the other girls and comes running up to her and she&#39;s like, terrified. She&#39;s like, did you just see that? Like, all of these people got killed. And Courtney and the other girl are like, oh, boy. Well, yeah, about that. I saw the the frats sticker on the back of their car.</p>
                            <p>Bryan White (41:15.872)
                            the look that this girl who was just terrified a moment ago gives her, it says so much, it says so much because like she doesn&#39;t immediately go like oh okay like I get it she it&#39;s more like oh all right.</p>
                            <p>Dave (41:33.218)
                            Which I feel like is a really good reaction. Cause I feel like I&#39;d be like, wait, really?</p>
                            <p>Bryan White (41:37.708)
                            I would be her as well, like that was supposed to be funny?</p>
                            <p>Dave (41:42.094)
                            Because that is objectively not funny.</p>
                            <p>Bryan White (41:45.368)
                            Not at all. Nope. It&#39;s so weird. And I don&#39;t know. Maybe it&#39;s just, maybe it&#39;s just.</p>
                            <p>Dave (41:49.054)
                            I do, what I do think is kind of interesting is that there&#39;s a kind of the way they treat this. So this is obviously what we&#39;d look at now and be like, oh, it&#39;s a school shooting. You know, the thing we do every day now. But instead they look at it as though it&#39;s a terroristic act. And I think it&#39;s really interesting to consider that in 1981, they would view this as a act of foreign terror.</p>
                            <p>Bryan White (42:15.064)
                            Well, sure, because it wasn&#39;t just a van full of guys with guns. They had the ski mask on, which was really like that look came from Black September. Oh yeah, in the Libyan times. Yeah, yeah. You know, like that, like the assassination in Munich was fresh in people&#39;s minds still, because that was only a few years before this movie came out.</p>
                            <p>Dave (42:20.598)
                            Right, they have the balaclava, yeah.</p>
                            <p>Dave (42:27.762)
                            Oh yeah, it&#39;s the Libyans, Marty. Like, that&#39;s what this is.</p>
                            <p>Dave (42:42.03)
                            It&#39;s just wild to me that like, they&#39;re like, oh, it&#39;s a terror, an act of terror. And like now we&#39;re just like, no, it&#39;s not. I mean, yes, it is. But yeah, I mean, it&#39;s just, it&#39;s just an angry white guy.</p>
                            <p>Bryan White (42:48.428)
                            Yeah, no, no, it&#39;s just an in-cell with a chip on a shoulder.</p>
                            <p>Bryan White (42:54.496)
                            Yeah. Yep. But we just shake it off like it&#39;s no big deal. And we move to a lunch hall where we get introduced to Gary the Pledge, who this poor guy, I feel so bad for him. And also I don&#39;t because he&#39;s trying to rush this frat. And fuck those guys. But it turns out.</p>
                            <p>Dave (43:18.73)
                            And like really, like he&#39;s real serious about it.</p>
                            <p>Bryan White (43:21.764)
                            He&#39;s really into it, yeah. But it turns out that the shooting wasn&#39;t just a prank. It was supposed to be a distraction so Mark could cheat on his test. And so what he does is as the shooting is happening, what we see him do is he pulls out a red pen, he marks a few things out wrong and he gives himself an 82. And he leaves the room while everybody&#39;s sort of.</p>
                            <p>Dave (43:43.442)
                            Now is it just me or is this an incredibly elaborate way of getting out of a test?</p>
                            <p>Bryan White (43:47.716)
                            That&#39;s what I said. Surely there are easier ways to cause a distraction. You don&#39;t have to go out and drive your van recklessly into a quad full of people and then let...</p>
                            <p>Dave (43:57.602)
                            Yeah, this shit has, this has props, this has costumes, this has special effects. Yeah, it really is, like this, this is a production.</p>
                            <p>Bryan White (44:03.759)
                            It&#39;s a movie within a movie.</p>
                            <p>Bryan White (44:08.268)
                            Yep. Yep. But the</p>
                            <p>Dave (44:11.146)
                            And it&#39;s the goddamn South, so we know that it&#39;s not going to be that high quality.</p>
                            <p>Bryan White (44:16.98)
                            But I think, is this the part where Mark takes him out like outside and is like, you got to go and steal this test for me? Like, that&#39;s that&#39;s the thing. So yeah, like there&#39;s there&#39;s a bit of this where, again, Mark is at lunch with everybody and he takes Gary the pledge out and he&#39;s and he tells he gives him his next like his next mission, which is to</p>
                            <p>Dave (44:37.694)
                            And this was the point at which I was like, like I love the fact that they&#39;re all just kind of fucked up looking. Like they&#39;re all just like, they just look like regular people. When you put a camera on them and you put them under some lights, it&#39;s just like, it reminds me of that George Carlin bit about like, look at yourself in a mirror in like a gas station bathroom. Like under neon lighting, it&#39;s just like, God damn, I love that everyone just looked like shit and that was cool.</p>
                            <p>Bryan White (44:46.968)
                            Yeah, yeah.</p>
                            <p>Bryan White (45:03.558)
                            Oh god, I know, I know, like they just didn&#39;t have like a makeup artist or whatever. But they definitely had like grips to do lighting because the lighting in this movie. They&#39;re just natural, man.</p>
                            <p>Dave (45:12.686)
                            But you didn&#39;t need to have a makeup artist, and that&#39;s the best part.</p>
                            <p>Dave (45:18.838)
                            Like, okay, maybe Faye Dunaway did, but everybody else who wasn&#39;t Faye Dunaway, you just looked the way you looked. Sometimes you&#39;re Allie McGraw and you have giant eyebrows and that&#39;s fine.</p>
                            <p>Bryan White (45:25.688)
                            Yeah, so as.</p>
                            <p>Bryan White (45:29.88)
                            Oh, yeah. Yep. And also this introduces is it Karen? Is that her name? She&#39;s the kind of ditzy one who&#39;s like in love with? Yeah, well, they&#39;re both blonde. But like, I know one&#39;s Janet and one&#39;s Karen.</p>
                            <p>Dave (45:44.078)
                            Is she the blonde one? Okay, let&#39;s dispense with the PC of it all. Is she the slutty one?</p>
                            <p>Bryan White (45:53.748)
                            No, I believe that&#39;s Janet. But yeah, there&#39;s a couple of the friends, just to knock, just to mark them off on your bingo card. We&#39;ve got the chase final girl. We&#39;ve got a kind of ditzy girl who&#39;s just sort of like, yeah, she&#39;s in love with being in love. And then there&#39;s Janet, who&#39;s the slutty one. And I believe we kind of meet all three of them in this scene. But.</p>
                            <p>Dave (46:08.944)
                            She&#39;s Gary&#39;s girlfriend.</p>
                            <p>Bryan White (46:22.36)
                            We&#39;re also introduced to Chekov&#39;s tray elevator, which is like a, literally, it&#39;s like a dumb waiter with an elevator.</p>
                            <p>Dave (46:26.966)
                            Okay, because initially my thought was, what is this fucking weird shot where it&#39;s like, lowers you down? I&#39;m like, why are they doing this? Because there&#39;s this moment where they&#39;re like, if she puts the tray on the thing and it lowers down into like, I don&#39;t know, I guess what you would call the dish pit.</p>
                            <p>Bryan White (46:32.314)
                            I know!</p>
                            <p>Bryan White (46:35.105)
                            It&#39;s the...</p>
                            <p>Bryan White (46:41.204)
                            like the kitchen or the the dish pit. Yeah. But yeah, like because then because she doesn&#39;t just put it in there like there&#39;s a shot of the camera on the elevator like going down and like getting onto like to onto the the conveyor belt. That&#39;s like though like this. It&#39;s one of these movies like occasional artsy flourishes because there&#39;s there&#39;s a couple of scenes later on that I was like watching and this</p>
                            <p>Dave (47:03.342)
                            Because it&#39;s one of those moments where you know somebody on this crew saw that thing. It was like, oh, we&#39;re coming back to that.</p>
                            <p>Bryan White (47:09.132)
                            Yeah, yeah. But like this, again, we go to another like really long bit of filler where we&#39;ve watched the guy like take the tray off the thing and then put it and empty it in the garbage and put it in the dishwasher and then he empties the, he ties up the garbage and he takes it away and we watch him leave the building and go out back to the dumpster and throw it away for some reason.</p>
                            <p>Dave (47:31.451)
                            and stare at her ass when she walks by.</p>
                            <p>Bryan White (47:33.584)
                            That&#39;s true, she does, because Courtney also walks out, but like, there&#39;s no reason for this, but she passes by the killer&#39;s car.</p>
                            <p>Dave (47:38.562)
                            So this is, but this is the thing about the seventies. It&#39;s like, I think now we&#39;re so accustomed to like, everything just fucking bangs along. Like if you lose someone after four minutes, you&#39;re done. Like the movie&#39;s over. So everything is like a Marvel movie. In the seventies, you&#39;re just fucking hung out. Like everyone&#39;s like, oh, the phone is ringing. Excuse me while I go get the phone. You just, the camera pans and then follows them down the hallway for a solid 10 minutes until they get to the phone. They casually answer the phone.</p>
                            <p>Dave (48:09.134)
                            talk for a minute, they hang it up. It&#39;s that, it&#39;s that thing of like, you know, why are we in a hurry? There&#39;s no internet. There&#39;s three channels. What are you gonna do, read the newspaper? Fucking hang out, man, don&#39;t worry about it. Yeah, you got nowhere to be. Put your tray on the weird fucking Chekhov&#39;s tray thing and hang out.</p>
                            <p>Bryan White (48:16.72)
                            I don&#39;t know, they were so mellow. They were so mellow back then.</p>
                            <p>Bryan White (48:23.044)
                            I know. I know, TV Guide was like an inch thick at the time. Yeah.</p>
                            <p>Bryan White (48:29.96)
                            Uh...</p>
                            <p>Bryan White (48:34.068)
                            Yep. So I would also, I would like to point out that like a solid hour or two has passed between this scene and the shooting and the fucking cop finally shows up.</p>
                            <p>Dave (48:44.242)
                            Okay, so that was my question, because I was like, wait, didn&#39;t that happen like three hours ago?</p>
                            <p>Bryan White (48:51.768)
                            And he is so pissed! He is so pissed that he&#39;s being asked to do his stupid job.</p>
                            <p>Dave (48:57.866)
                            And this guy who is the sheriff, my note just says, the sheriff is also a dildo.</p>
                            <p>Bryan White (49:02.648)
                            He is Roscoe P. Coltrane, again, from the fucking Dukes of Hazzard. He is...</p>
                            <p>Dave (49:06.582)
                            but he is giving you his best Strother Martin from Cool Hand Luke, like by way of every sheriff from every Scooby-Doo episode.</p>
                            <p>Bryan White (49:11.undefined)
                            Hahaha!</p>
                            <p>Bryan White (49:14.34)
                            Oh my God, he 100% is like, it&#39;s yeah. Like he probably, cause it turns out that the sheriff was also the dialogue coach on the movie. So I don&#39;t know if he&#39;d ever acted. They were just like, I know they were like, okay, so you&#39;re a cop. And he&#39;s like, okay, but what kind of a cop? And they&#39;re like, I don&#39;t know. Like, you know, you&#39;ve got this mirror shades and you got the stupid hat. Yeah.</p>
                            <p>Dave (49:28.046)
                            That explains a lot.</p>
                            <p>Dave (49:38.314)
                            He&#39;s like, oh, a heck. Okay.</p>
                            <p>Bryan White (49:40.956)
                            Oh, and again, like this is, and this is why I confused him at the end with the, the coach, because they look the same. They both talk the same. It&#39;s if he, he may as well be the coach, but he&#39;s got this like pissed off attitude about coming down and responding to, uh, what to, you know, everybody else on campus was a real shooting.</p>
                            <p>Bryan White (50:06.804)
                            except that like it took them five minutes later after it had happened to figure out that it wasn&#39;t. But I get the feeling that he would have been just as pissed if it were a real shooting. Like he would have been.</p>
                            <p>Dave (50:15.67)
                            Yeah, he may as well have just made a comment about how we lost Vietnam while he&#39;s at it. Like everything about this guy is just like, this is America.</p>
                            <p>Bryan White (50:20.153)
                            Ha ha ha.</p>
                            <p>Bryan White (50:23.596)
                            Yep. And he comes up, he comes up like basically like hype and hiking up his pants and shit like that. As like, yeah, with, and it&#39;s Radish and Mark and Courtney and like basically everybody who witnessed this thing. And his attitude is basically like, you&#39;re the one, he goes to Radish and he&#39;s like, you&#39;re the one who called me, weren&#39;t you? And the Radish is like, I didn&#39;t say I wasn&#39;t. And it-</p>
                            <p>Dave (50:29.462)
                            Because he&#39;s Boss Hog.</p>
                            <p>Dave (50:49.11)
                            Yeah, he&#39;s like, I called you because it&#39;s a fucking school shooting, man. You&#39;re the sheriff. Who else am I going to call?</p>
                            <p>Bryan White (50:52.276)
                            Yeah. Yeah. Yep, this is not the first time that they&#39;re going to tangle. He says to him, and he pleading, again, this is a radish, and he&#39;s pleading with the sheriff. He&#39;s like, why wouldn&#39;t I call? He&#39;s like, senseless murders are a modern phenomenon. These type of murders happen all the time.</p>
                            <p>Dave (51:11.426)
                            Like, Radish, man, you are just begging to be called a queer.</p>
                            <p>Bryan White (51:15.668)
                            And that&#39;s the thing, like, and his dialogue, his lines are really specific. Like, I wonder if he wrote them or something because like, oh my God, that&#39;d be so great. His lines are so specifically weird, like, and like he uses really technical language. And one of the times that the cop comes over and he&#39;s like, so where&#39;d you learn to talk like that? You know, and what is it? Wildman says, uh, uh.</p>
                            <p>Dave (51:23.37)
                            did. Because he is immensely talented.</p>
                            <p>Bryan White (51:46.088)
                            Oh, Dragnet. He says, watching Dragnet. So like he talks like a cop. Even when he was on the phone making the initial phone call, he&#39;s like, it&#39;s a multiple shooting with bodies.</p>
                            <p>Dave (51:53.307)
                            Like, listen, man, I have seen every episode of Dragnet and aside from the fact that that show sucks, there is never an episode where there is a school shooting or a terroristic act. Yeah.</p>
                            <p>Bryan White (52:08.044)
                            No, it&#39;s mostly just like hippies on acid and smoking weed and shit. Yeah, his man, but yeah, this cop, his manner. Yep, his manner is so shitty. And now the coach comes in and it&#39;s two of the worst characters in the movie in the same scene. And. What is that coach doing? What is that coach doing?</p>
                            <p>Dave (52:14.358)
                            More improv.</p>
                            <p>Dave (52:25.17)
                            And what is that coach carrying? But Chekhov&#39;s bow and arrow.</p>
                            <p>Bryan White (52:29.884)
                            Yeah, yep, Chekhov&#39;s bow and arrow. Oh, but this is the whole time where he&#39;s like, because the cop is like, I&#39;m gonna take you guys in, I&#39;m gonna run you in, I&#39;ll throw the book at you, or take you to the station to spend the night in the Who&#39;s Cow. And the coach is like, now look, we don&#39;t have to get involved. Like his whole thing is like, he&#39;s got this chill, like he&#39;s definitely just smoked a joint kind of attitude. Like where the cop is like cocaine, the coach is weed.</p>
                            <p>Bryan White (52:59.636)
                            or quailudes, whatever they were doing in the 70s. But also the cop keeps calling Wildman Bulldog. So is his name Wildman or is it Bulldog? Like where did that come from? Cause it&#39;s not mentioned anywhere else. Yeah, maybe, maybe. I don&#39;t fucking know. But then here comes the school&#39;s like night watch man. So now we&#39;ve got three shitty Southern like stereotypes in one scene.</p>
                            <p>Dave (53:13.638)
                            I think it&#39;s like a football thing. Is it a football thing? I don&#39;t understand.</p>
                            <p>Bryan White (53:29.932)
                            And his whole thing is he says to the cop something like, not like I haven&#39;t had one, there&#39;s been no crime on my watch or something like that. It&#39;s like, I beg to defer my friend.</p>
                            <p>Dave (53:45.406)
                            was a school shooting moments ago or hours ago. I&#39;m not really sure.</p>
                            <p>Bryan White (53:46.532)
                            there was uh... figures was like this there&#39;s there&#39;s frapple is breaking into teachers offices to steal tests uh...</p>
                            <p>Dave (53:56.782)
                            There&#39;s a teacher having sex with a student. We skipped that part, but we can come back.</p>
                            <p>Bryan White (53:59.072)
                            With a stew. Oh, that&#39;s right. I completely danced past that. Like we&#39;ll talk about that in a second cause that scene goes on forever and it is so gross. But yeah.</p>
                            <p>Dave (54:06.87)
                            Yeah, that is actually what it says in my notes is, this scene is gross.</p>
                            <p>Bryan White (54:11.136)
                            Yep. Oh, no. But yeah, but like later on, Wildman breaks in and steals like fucking opiates from the, from like the phys ed doctor or something. He&#39;s looking for speed.</p>
                            <p>Dave (54:20.958)
                            Oh no, he&#39;s looking for speed? It&#39;s just like, why would a college doctor have speed?</p>
                            <p>Bryan White (54:27.192)
                            Well, that&#39;s what he&#39;s tell... we&#39;ll get there, we&#39;ll get there. Let&#39;s take a step back though, because God, I can&#39;t believe I don&#39;t even have any notes on this, but yeah, so after... eventually what happens when they&#39;re about to do the test is the teacher, Kurt Vonnegut, leaves the room and he goes off to presumably what is his office, where he meets Karen or Janet, whatever her name is, the slutty one, and it is immediately revealed that he&#39;s having like a sexual relationship with her.</p>
                            <p>Dave (54:56.662)
                            And this is another one of those moments where it&#39;s just like, okay, she is a hot young college student. He is a southern professor. Yeah, like, but without the things that drew people to John Holmes, like.</p>
                            <p>Bryan White (55:02.112)
                            And he is a dumpy, John Holmes looking dude. Right, right, right. He&#39;s got the weird like late era Mike Brady perm. He&#39;s got this shitty mustache. And they&#39;re very clearly in cahoots. They&#39;re conspiratorial. The way they talk about his wife is gross as fuck.</p>
                            <p>Dave (55:27.998)
                            Yeah, it is weirdly misogynistic, but also you just feel bad for everybody.</p>
                            <p>Bryan White (55:33.372)
                            She what does she say? She&#39;s got a she&#39;s she must be going on what 30 now? I&#39;m like, fuck you lady I can&#39;t wait for you to die Oh god, it&#39;s it&#39;s so nasty I know I know and what does he say? Oh and she calls She calls She calls him. Uh Uh, what an owner she calls her like that redheaded wife of</p>
                            <p>Dave (55:45.454)
                            Also, let&#39;s talk about looking 30.</p>
                            <p>Dave (55:51.214)
                            Speaking of misogyny...</p>
                            <p>Bryan White (56:02.328)
                            or something, and then he&#39;s like, hey, you&#39;re talking about the mother of my guppies or something like that, like the entire exchange between</p>
                            <p>Dave (56:09.354)
                            get this is more improv all of this is just more</p>
                            <p>Bryan White (56:12.788)
                            It must have been, because it&#39;s just so nasty. And unfortunately...</p>
                            <p>Dave (56:15.446)
                            But I&#39;m, you know, it makes me uncomfortable and it&#39;s disgusting. And it&#39;s like, first of all, this is not, neither of you would be.</p>
                            <p>Bryan White (56:22.001)
                            No, no, but like he&#39;s trying to coerce her into.</p>
                            <p>Dave (56:23.582)
                            in Chinatown Kurt Vonnegut and... blonde girl.</p>
                            <p>Bryan White (56:28.985)
                            He&#39;s trying to coerce her into having sex in the office right then and there and she&#39;s like no, you know, she wants him to like come up to her, you know, come up and see me sometime at the conservatory or wherever it is that she&#39;s going.</p>
                            <p>Dave (56:42.902)
                            and the attic of the Gothic cathedral that she lives in. We&#39;ll get there later.</p>
                            <p>Bryan White (56:47.164)
                            Yeah, we&#39;ll get there. But yeah, like it&#39;s just a setup to kind of put her in place later on when she&#39;s going to get killed. Unfortunately, he never comes back. So we don&#39;t get to see him get killed, which is like the most unfortunate thing of the the the entire movie, because if anybody deserves to get killed in this movie, it&#39;s that guy like his wife will be. Yeah, yeah, he&#39;s definitely got like a Dr. Johnny fever thing going on, but like shitty like he&#39;s the AM radio version of Dr. Johnny fever.</p>
                            <p>Dave (57:06.314)
                            Yeah, it&#39;s like poor man&#39;s Howard Hesman.</p>
                            <p>Dave (57:16.694)
                            is pretty wild because you get the feeling that Howard Hesman&#39;s probably not a great guy. And I- apologies, apologies to the Howard Hesman fans of the world.</p>
                            <p>Bryan White (57:23.843)
                            I don&#39;t know. I presume nothing about him.</p>
                            <p>Bryan White (57:29.836)
                            I know nothing about him. So like, yeah, now that we&#39;ve kind of got that out of the way, there is like, let&#39;s go back to the, to the scene where the coach and the cop are talking because they closed this out with one of the most fucked up exchanges that&#39;s supposed to be cute and funny, but it&#39;s not, it&#39;s really, really weird. So the coach, no, no, no, the coach recounts a story about how.</p>
                            <p>Dave (57:51.507)
                            of a hunting the hunting line</p>
                            <p>Bryan White (57:58.02)
                            The library caught on fire, and someone hid the fire department&#39;s only hose, apparently. And then all the cops showed up. They&#39;ve got one hose, and Cletus hit it again. Oh, that Cletus. And all the cops showed up, and they drank beer, and they chanted, damn good fire, until the place was nothing but ash, apparently. This whole fucking campus deserves a slasher maniac.</p>
                            <p>Dave (58:06.082)
                            Which again, that just sounds southern as hell.</p>
                            <p>Dave (58:12.074)
                            Yeah, this is a joke.</p>
                            <p>Dave (58:27.198)
                            Yeah, if they could have gotten away with inserting a few racist references into that scene, they would have.</p>
                            <p>Bryan White (58:32.092)
                            Oh my god, I am so surprised that they held off from doing that. But, ugh. Everybody in the cell is like, why do they say bad things? I don&#39;t know. I don&#39;t know. Oh, their representation is, it needs work. It needs. Coming from everywhere. But again, Duke&#39;s a hazard. It&#39;s like they cast all these bit players from Duke&#39;s a hazard. Because the cops are going fishing or something, and there&#39;s something about a scatter gun.</p>
                            <p>Dave (58:38.218)
                            And then everybody in the South is like, why do they say bad things about us? I don&#39;t know.</p>
                            <p>Dave (58:46.806)
                            Yeah, I&#39;m coming for everybody this time. It&#39;s been a wild day.</p>
                            <p>Dave (59:00.93)
                            Yeah, they&#39;re gonna go hunting. And that&#39;s why he&#39;s like, you gonna bring that queer bow and arrow or whatever? I don&#39;t fucking know.</p>
                            <p>Bryan White (59:06.056)
                            Yeah, I don&#39;t know. I don&#39;t fucking know. But from here, the killer</p>
                            <p>Dave (59:12.598)
                            But at the same time, everybody is just like a little bit amused. That&#39;s the weird thing about this whole movie is that at no time is there not someone in the scene with a smile on their face. So they&#39;re either having a really fucking good time, in which case I love it, or they&#39;re just trying to sell you like, nobody&#39;s taking the cops seriously. And it&#39;s like, okay, but there was still a school shoot. Like I know it was a fake school shooting, but it still could have been a real one.</p>
                            <p>Bryan White (59:23.632)
                            They&#39;re all very pleased with themselves.</p>
                            <p>Bryan White (59:40.025)
                            You would think! You would think that somebody would get arrested for that sort of thing! Like, this was pretty... a chaotic, hectic scene! Ugh!</p>
                            <p>Dave (59:48.654)
                            And there&#39;s poor weird radish who&#39;s like, look, man, I&#39;m just trying to tell you what I saw. And it was this fucking dude over here. Oh.</p>
                            <p>Bryan White (59:53.072)
                            Yep. And this book could do it over these. Radish did. Radish did nothing wrong. Nope. But speaking of Radish, his room is awesome. Because now we go to his room. Or wait, no, no, no. We go to Courtney&#39;s room first. And this is the first time that we see the killer kind of stalking around. So she, this is a scene that&#39;s lifted directly from Halloween where she looks out her window and she sees him kind of walk behind a tree. It&#39;s kind of like the scene where like.</p>
                            <p>Dave (01:00:20.682)
                            Oh, you know what the difference is though? That scene in Halloween is fucking cool as hell. And in this movie, I don&#39;t care. I don&#39;t even remember it.</p>
                            <p>Bryan White (01:00:26.036)
                            It&#39;s awesome. Like, let&#39;s see. Yeah, that&#39;s the thing. Like, yeah, it&#39;s like, oh, okay. Well, neither did they because like we get to a point where like he&#39;s not masked. They don&#39;t really obscure him at all. Like I got thoughts on the killer. But yeah, like this is this is the part where it&#39;s like, okay, don&#39;t just just so everybody doesn&#39;t forget this is a horror movie and here&#39;s the killer because this is like we&#39;re we&#39;re now like 45 minutes into a movie that runs 90 minutes.</p>
                            <p>Bryan White (01:00:55.413)
                            An entire hour goes by before the bodies start dropping.</p>
                            <p>Dave (01:00:57.738)
                            This thing doesn&#39;t become a horror movie until 55 minutes in.</p>
                            <p>Bryan White (01:01:01.928)
                            Oh, but um, yeah, so so there&#39;s that and then she goes to Radish&#39;s room, which again, like I said is awesome. He appears to live on his own because like it this is just like all of the decorations in this room are just him. He&#39;s got this weird picture of what appears to be himself next next to his desk. I don&#39;t I kind of get the feeling that it&#39;s supposed to be somebody that Either we</p>
                            <p>Dave (01:01:28.714)
                            Oh, it&#39;s like a drawing, right?</p>
                            <p>Bryan White (01:01:30.356)
                            No, no, it&#39;s like a photograph, or it actually looks almost like a printout of a picture from the internet or something, but there&#39;s no internet at the time. But I have no idea who this guy is.</p>
                            <p>Dave (01:01:35.106)
                            Yeah.</p>
                            <p>Dave (01:01:41.79)
                            I don&#39;t know, it&#39;s either Radish or Andrew Kunanin, I don&#39;t know.</p>
                            <p>Bryan White (01:01:46.288)
                            But I get the feeling like he&#39;s either somebody that we&#39;re supposed to recognize or it&#39;s an in joke for the Cast and crew like I don&#39;t know because it&#39;s very prominently featured in the scene But he&#39;s got like horror movie posters up all over the place. He&#39;s reading helter-skelter. There&#39;s like science shit all over the place</p>
                            <p>Dave (01:02:02.07)
                            But he&#39;s got like some real like kind of fringe, like there&#39;s a toolbox murderous poster on the wall. Yep.</p>
                            <p>Bryan White (01:02:08.064)
                            Yep, yep. Corpse Grinders is also in there. Yeah, yeah. Like, I don&#39;t know, maybe this is them trying to prove their bona fides or something like that, but he... He&#39;s right in. Yeah, yeah. But yeah, he&#39;s got a great room and Courtney goes in and they have a chat, which is just like her going like, how come Karen or Janet, whichever the hot one is, how come she&#39;s got it so easy and I gotta work so hard?</p>
                            <p>Dave (01:02:20.002)
                            Well, try harder.</p>
                            <p>Bryan White (01:02:37.3)
                            This is kind of the tenor of their conversation that he just sort of reassures her. Um, but then she.</p>
                            <p>Dave (01:02:42.162)
                            in a way that is, again, that&#39;s sort of ambiguous in terms of like, what&#39;s his angle? Like, is he just being like, is he like, I&#39;m just a nice guy, so I&#39;m gonna tell you that you&#39;re a hot lady, but is he in turn?</p>
                            <p>Bryan White (01:02:52.312)
                            No, we find out that he&#39;s the end-doer. Yeah. I, I.</p>
                            <p>Dave (01:02:58.454)
                            We&#39;ll get to that moment because you think you have thoughts before.</p>
                            <p>Bryan White (01:03:03.516)
                            Yeah, yeah, but she goes back to her room and somebody has moved her stuff around. She could swear that like her the things on her desk have been moved and then the hot one, Janet or Karen, whatever her name is, she comes in and she opens her closet and like a book falls down and like almost gives her a heart attack. She&#39;s so scared of it.</p>
                            <p>Dave (01:03:29.511)
                            Yeah, she doesn&#39;t mind a school shooting, but a book falling off a shelf scares the shit out of her.</p>
                            <p>Bryan White (01:03:33.929)
                            And she&#39;s so aggravated by this she thinks that Courtney set up this this trap is like a joke or something like that But no, this is this is the murderer Menacing the students he goes in and he kind of moves their desk around a little bit and then he leaves a really shitty trap</p>
                            <p>Dave (01:03:49.226)
                            Like he&#39;s the most annoying murderer.</p>
                            <p>Bryan White (01:03:51.436)
                            Yep. And then I have a note, and I don&#39;t remember what it was from, but it just says in quotes, there is no free brunch, which I think is what Radish tells her, tells Courtney, because he&#39;s like, she&#39;s.</p>
                            <p>Dave (01:04:02.926)
                            That&#39;s more of that. More of that real solid improv.</p>
                            <p>Bryan White (01:04:06.028)
                            Yeah. Yeah. What&#39;s what&#39;s brunch? Yeah.</p>
                            <p>Dave (01:04:10.186)
                            It&#39;s not quite breakfast. It&#39;s not quite lunch.</p>
                            <p>Bryan White (01:04:13.712)
                            You get a slice of cantaloupe at the end. You get a good meal. But she&#39;s talking to a, oh, right, because she wants to be hot. She wants things to be like Janet Caron. And she wants her life to be easy. And Radish says, I want to be on the SWAT team. And. What the fuck, man?</p>
                            <p>Dave (01:04:33.814)
                            Radish, what the fuck, man? You can&#39;t even place a phone call without freaking out.</p>
                            <p>Bryan White (01:04:40.552)
                            Yep. Yep. I want to be on the SWAT team. So he&#39;s like really into the into the cops, but he&#39;s also really into serial killers. But, uh, yeah. So this is when this is when the frat bros we go back to Radish&#39;s room and this is when the bros like burst in on radish and he&#39;s listening to some kind of like Renaissance Fair Judy Collins music.</p>
                            <p>Dave (01:04:50.161)
                            Go either way.</p>
                            <p>Dave (01:05:03.192)
                            And my note also says, why is he listening to Renaissance fare music?</p>
                            <p>Bryan White (01:05:10.201)
                            And it&#39;s stupid, the scene is just more filler.</p>
                            <p>Dave (01:05:11.854)
                            I&#39;m not talking like cool, dead-can-dance, Renaissance Faire music. This is like real Renaissance Faire music.</p>
                            <p>Bryan White (01:05:17.313)
                            Radish? No, no, it&#39;s like a woman with a lute, like singing about like, oh, I don&#39;t even know, but it&#39;s so off-putting and strange, but also really appropriate for radish. So this scene is just more filler, like they come in, I think really what they&#39;re trying to do is like they steal his keys or something.</p>
                            <p>Dave (01:05:36.374)
                            But it doesn&#39;t like, you get the feeling that maybe the direction was you&#39;re going in to steal the keys, now go or whatever, however one directs a movie like this. But it doesn&#39;t actually have that feeling of like everyone in the scene gets that. It&#39;s only the one advertising dude who&#39;s like, he knows, but then Wild Man is like, I&#39;m just here to fuck with this kid. Maybe?</p>
                            <p>Bryan White (01:05:56.876)
                            Yeah, because it&#39;s... Yeah, because he like grabs him by the face and like squeezes his whips and it&#39;s... It&#39;s Wildman being Wildman. Yeah, but this movie... That&#39;s the way the 70s. He... This movie like very... We are almost at... We are almost... We are over halfway through this movie.</p>
                            <p>Dave (01:06:07.543)
                            What?</p>
                            <p>Dave (01:06:13.254)
                            Sometimes you gotta just let wild man be wild.</p>
                            <p>Bryan White (01:06:26.832)
                            very little happens. Yep. Yep. And there&#39;s been...</p>
                            <p>Dave (01:06:29.102)
                            Nobody, no one has died since the beginning. You have seen no killer. You&#39;ve just seen like college kids being weird.</p>
                            <p>Bryan White (01:06:37.676)
                            Yep, and there&#39;s been like a little bit of like you see the killer&#39;s legs or something or you see him kind of Michael Meyers saying in the background or something like that, but very little regard is paid to him until as a till like they said 55 minutes in the fucking bodies start to drop that but like that is a long, long ways to wait in a slasher movie for a fucking killer because also the movie is really mild like it&#39;s barely rated R. Were it not for</p>
                            <p>Dave (01:07:05.93)
                            Well, until you get to the weird tree moment.</p>
                            <p>Bryan White (01:07:10.422)
                            Yeah, but um...</p>
                            <p>Dave (01:07:12.31)
                            where everything just takes a hard turn towards somehow weirder.</p>
                            <p>Bryan White (01:07:16.972)
                            Yep, so speaking of the tree scene, the pledge Gary, he gets treed, which means that the frat tears his clothes off, and then they tie him to a tree and abuse the hell out of them out of him. And he appears to be bleeding in a couple of these scenes.</p>
                            <p>Dave (01:07:33.654)
                            Yeah, no, so everybody is laughing. Like this is just like, ah, it&#39;s a fun college prank, except, and Gary is laughing too. Gary&#39;s also bleeding in multiple places on his body, and the whole thing just is inherently dangerous. They&#39;re dumping ice down his underwear, which is like, that definitely is not safe.</p>
                            <p>Bryan White (01:07:47.416)
                            He&#39;s covered... ice?</p>
                            <p>Bryan White (01:07:53.9)
                            Yeah, they pour water all over him, they stuff ice down his underpants, they cover him in like shaving cream, and they tie him to the tree in this like Shibari rope torture rig. And these are the movie&#39;s heterosexuals. It&#39;s</p>
                            <p>Dave (01:08:03.03)
                            Yeah, it&#39;s like, it&#39;s just some weird S&amp;M shit.</p>
                            <p>Dave (01:08:09.942)
                            Yeah, this scene is very gay.</p>
                            <p>Bryan White (01:08:14.445)
                            It&#39;s so crazy because also like as they&#39;re like watching him struggle like wild man pulls on a lighter He&#39;s like, hey, let&#39;s set him on fire and I&#39;m like, I Don&#39;t know if Gary could hear that. So like maybe he&#39;s serious. Like he&#39;s just that carried away in the moment But yeah</p>
                            <p>Dave (01:08:33.014)
                            They&#39;ve already done enough real dangerous shit to him.</p>
                            <p>Bryan White (01:08:36.448)
                            Yep. He&#39;s like, so it&#39;s not just me. He does appear to be bleeding because like, this is not the last time we see it. Like later on the night watch man comes by cause this is like middle of the day. And then later at night, the night watch comes by and sees him and he&#39;s like, Oh, thank God you got to get me out of here. And like the, instead of helping him and now he looks like he has like makeup appliances on him that looks like he&#39;s been like stabbed or something. Cause he&#39;s like very seriously bleeding and instead of helping him.</p>
                            <p>Bryan White (01:09:03.78)
                            The fucking Nightwatch guy pours whiskey on his dick.</p>
                            <p>Dave (01:09:06.774)
                            Yeah, Gary is in a real precarious place right now. In terms of health.</p>
                            <p>Bryan White (01:09:10.948)
                            And we cut. I this yeah, he he he if he joins this frat, he will have nearly died several times on the way to initiation. But like we cut away and it&#39;s more of Mark and Wildman in the frat house, which is a shithole like I don&#39;t know what frat houses look like, but I hope they don&#39;t look like this, because this looks like a fucking heroin flop house. And both of them are you know.</p>
                            <p>Bryan White (01:09:39.792)
                            drunk acting in this scene in a way that&#39;s really annoying.</p>
                            <p>Dave (01:09:43.434)
                            Listen, I went to the college that gave us fish. The band. We did not have frat houses.</p>
                            <p>Bryan White (01:09:47.536)
                            Ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha</p>
                            <p>Bryan White (01:09:51.732)
                            No, because everybody&#39;s high, man. Yeah. But yeah, but like this scene, like he&#39;s like shooting shaving cream in his mouth and like spraying antiperspirant in his mouth and like just being.</p>
                            <p>Dave (01:09:54.246)
                            Oh, yes, they were.</p>
                            <p>Dave (01:10:07.522)
                            Yeah, like, what the fuck is that? All of a sudden, he&#39;s just, like, he&#39;s kind of like a goofy, lovable, like, kind of, oh, you&#39;re like a wingnut. I think you&#39;re fun. And then all of a sudden, he&#39;s just a goddamn lunatic.</p>
                            <p>Bryan White (01:10:11.989)
                            I can, I cannot.</p>
                            <p>Bryan White (01:10:18.369)
                            I think we&#39;re supposed to like him. Yeah, I think we&#39;re supposed to like him. We&#39;re intended to...</p>
                            <p>Dave (01:10:23.338)
                            He&#39;s like, I&#39;m gonna tie this dude to a tree, sexually torture him, then I&#39;m gonna go like, huff some fucking deodorant, and then I&#39;m gonna go wreck a place and look for speed. It&#39;s like a Jim Carroll novel.</p>
                            <p>Bryan White (01:10:37.817)
                            Yeah. That is the most high-profile.</p>
                            <p>Dave (01:10:37.938)
                            Also, that is the most highbrow joke you&#39;re getting out of me in this. You&#39;re gonna get misogyny, you&#39;re gonna get homophobia, you&#39;re gonna get literary references. That&#39;s my life in a nutshell.</p>
                            <p>Bryan White (01:10:45.039)
                            I&#39;m sorry.</p>
                            <p>Bryan White (01:10:49.048)
                            it&#39;s a good mix of highbrow and lowbrow. But yeah, so this scene, the plan is that they need to sell speed to other people who are trying to study all night and shit. And he&#39;s like, hey, wild man, you got pills over at the phys ed room. And he&#39;s like, no, we got like, painkillers. And Mark is like, well, what&#39;s the difference? Whatever gives you a buzz.</p>
                            <p>Bryan White (01:11:16.804)
                            There&#39;s a pretty significant difference between those two drugs. Yep. Yep. Yep. Yep. Yeah, but the mission is for Wildman to break in. So they&#39;ve had, we&#39;ve had a guy break in to a teacher&#39;s office to steal tomorrow&#39;s test and now we&#39;re having a guy break into another teacher&#39;s office to steal narcotics. So</p>
                            <p>Dave (01:11:18.178)
                            Yeah, what&#39;s the difference? What&#39;s the difference? They are literally the opposite of each other. Dummy.</p>
                            <p>Bryan White (01:11:43.288)
                            this like zero crime record that we&#39;ve got claimed on this place, man, fuck that Night Watchman. Thank God he gets... yeah, thank God he gets fucking murdered. So there&#39;s... so a girl eventually goes looking... okay, so you... this is great because I&#39;ve got notes on this girl. I have no idea what her name is. I don&#39;t think she&#39;s ever actually named in the movie, but she is fucking weird. So she... she goes...</p>
                            <p>Dave (01:11:48.118)
                            Yeah, cause this is some real Winter Hill Gang bullshit at this point.</p>
                            <p>Dave (01:11:59.715)
                            Huh.</p>
                            <p>Dave (01:12:04.102)
                            Yup.</p>
                            <p>Bryan White (01:12:13.892)
                            Gary&#39;s like, you got to go get Janet. Janet is her name. So Janet&#39;s the sort of lovestruck ditzy one, and Karen&#39;s the other one. She&#39;s convinced to go look for Janet. And she goes running.</p>
                            <p>Dave (01:12:30.058)
                            And this girl, she&#39;s kind of like a low, she&#39;s like bottom shelf pinky Tuscadero.</p>
                            <p>Bryan White (01:12:36.089)
                            So she&#39;s the same girl from the beginning after the shooting happens, and she&#39;s like, oh my god, did you see that? And the girls are like, oh, that frat. She&#39;s the one who has that kind of concerned look on her face, but she&#39;s also probably the most bloodthirsty person at the tree scene, where like this is like a frat event where all the frat bros are doing it, but she is vigorously applying the shaving cream to poor Gary.</p>
                            <p>Dave (01:12:53.326)
                            Yeah.</p>
                            <p>Bryan White (01:13:02.744)
                            But at this scene, she runs around campus looking for Janet. And at one point, we see her walking down a hall. And she reaches behind her and very busily, very busily picks a wedgie. I had to stop it and go back twice to make sure that that is what I saw her do. Yep. And, uh. But that&#39;s part of the movie. Yeah. Yeah. Yeah.</p>
                            <p>Dave (01:13:11.822)
                            Shit.</p>
                            <p>Dave (01:13:22.25)
                            Yes. This is the best part of the movie.</p>
                            <p>Bryan White (01:13:29.72)
                            Because it doesn&#39;t end there, like her weird behavior continues into the next scene where it&#39;s Karen and Courtney are in their room, or no, Janet and Courtney are in the room talking and this is where the girl walks in, and the two girls, the two sort of main girls are having a conversation, and this weirdo wedgie picking girl sort of...</p>
                            <p>Bryan White (01:13:57.584)
                            offers them a stick of gum and then like sheepishly retracts it when when neither of them are paying attention to her What the fuck this is like some this is the most the most brilliant comedy i&#39;ve seen in a while</p>
                            <p>Dave (01:14:15.119)
                            It&#39;s like, what if Jan from Greece was gross?</p>
                            <p>Bryan White (01:14:17.792)
                            Hahahaha</p>
                            <p>Dave (01:14:20.786)
                            Gross as hell. Oh god.</p>
                            <p>Bryan White (01:14:26.468)
                            Oh, oh, God. But finally, OK, so this is the one hour mark. And finally, the killing starts. And of all people to kill first, they had to kill Pledge Gary. And I got to say, Gary, of everybody they could have killed, Gary didn&#39;t do nothing to nobody. All he wants to do was get into that stupid frat and love his girlfriend. And he gets a t-</p>
                            <p>Dave (01:14:32.076)
                            Ugh.</p>
                            <p>Bryan White (01:14:52.476)
                            Untied first by the presumably it&#39;s a killer. He&#39;s behind the tree like cutting the ropes and then and then he gets killed</p>
                            <p>Bryan White (01:15:01.548)
                            And then.</p>
                            <p>Dave (01:15:02.77)
                            in like this sort of unceremonious and boring way.</p>
                            <p>Bryan White (01:15:06.656)
                            Yeah, most of the kills, fucking boring. Like, mo-</p>
                            <p>Dave (01:15:10.21)
                            except for one, which we are going to get to in a moment.</p>
                            <p>Bryan White (01:15:12.884)
                            Yeah, yeah. So now Janet comes thinking that he&#39;s there. And what she finds is the ropes on the tree and Gary&#39;s shirt on it. And for some reason, she&#39;s like, oh, Gary, you&#39;re just playing with me or whatever. And so she&#39;s.</p>
                            <p>Dave (01:15:30.07)
                            Yeah, she&#39;s like, Oh, you&#39;re being real sexy, Garrett. It&#39;s like, no, Gary was just tied to a tree for six hours and had ice and liquor dumped down his pants. Gary is. I tell you right now, Gary is not sexy.</p>
                            <p>Bryan White (01:15:41.128)
                            libido is probably at an all-time low for him like after yeah but she sees a um she sees a silhouette of a person off in the distance against the like the school&#39;s observatory and she&#39;s like oh gary and she calls out to him and uh uh you know gary doesn&#39;t call back and again this is not the first time this happens this this happens in the movie this is going to happen again where</p>
                            <p>Dave (01:15:44.034)
                            He should also be pretty pissed.</p>
                            <p>Bryan White (01:16:09.036)
                            She like walks along trying to get closer to him and she finds like more of his clothes on the ground. And of course to her, this is like, oh, it&#39;s like sexy time.</p>
                            <p>Dave (01:16:17.226)
                            And this is that fucking terrible scene from The Burning, which I mean, every scene in The Burning is a terrible scene. But I think they also do it in one of the Friday movies where it&#39;s like, oh, I found more clothes. Oh, here&#39;s more. It&#39;s very much like, ooh, piece of candy. Ooh, piece of candy.</p>
                            <p>Bryan White (01:16:23.62)
                            Hahaha</p>
                            <p>Bryan White (01:16:32.84)
                            Oh, peace, Katie. Yep. And she finally gets to the observatory and is like, oh no, that&#39;s not Gary. And she tries to get away. And then the killer jumps out and pulls her away. And we don&#39;t see her get killed. But presumptively, she dies.</p>
                            <p>Dave (01:16:49.354)
                            Yeah, they really kind of backhand load this movie with action.</p>
                            <p>Bryan White (01:16:52.02)
                            Yep. Um, so next we, we see wild man get up to the gym and he goes in and, um, for whatever reason, he opens all the bottles, like the bottles of pills, like it would have been more economic for him to just stuff the pill bottles in his fucking pockets. But he like,</p>
                            <p>Dave (01:17:12.754)
                            So I think why, because I was like, why is he wrecking this place? Like he&#39;s like a Tasmanian devil. And it&#39;s because they&#39;re supposed to make it look like a robbery.</p>
                            <p>Bryan White (01:17:19.808)
                            Yeah, well, one of the things they talked about was like.</p>
                            <p>Bryan White (01:17:23.832)
                            Yeah, Mark told him to do that, like bust it up, make it look like a break-in, because he says to him.</p>
                            <p>Dave (01:17:27.434)
                            Yeah, so I must have missed that part because I&#39;m like, why is he ripping this place apart?</p>
                            <p>Bryan White (01:17:31.116)
                            Yeah, it&#39;s during that really shitty part. You&#39;re probably distracted by him like, you know, spraying deodorant in his mouth and shit. Because like that&#39;s the scene where they talk about it. Mark&#39;s like, no, you can do this. Just like make it look like a break in. So he goes and he trashes the place and so and he opens all the bottles of pills. He dumps them out and then he grabs handfuls of them and stuffs them in his pockets. And then we see him like leave the office and go into the gym where he appears to be eating the pills like candy.</p>
                            <p>Dave (01:17:59.926)
                            Definitely not a good idea.</p>
                            <p>Bryan White (01:18:01.588)
                            No, that&#39;s actually a very bad idea. But this was one of the scenes where I was like, again, I was like, OK, because.</p>
                            <p>Dave (01:18:10.619)
                            you&#39;re like, oh, you&#39;re okay, final exam, you&#39;re about to do a thing.</p>
                            <p>Bryan White (01:18:14.188)
                            Yeah, because he, how do you describe this? So he walks into the main, to the basketball courts, and there&#39;s a big bright spotlight kind of on him, but he leaves the spotlight, and it&#39;s just him standing in pitch darkness, completely surrounded by this blackness. It&#39;s like something out of a Bogdanovich movie or something. It&#39;s very artful and-</p>
                            <p>Dave (01:18:35.082)
                            Yeah, like this is the one cool shot in this movie. Because the whole fucking, like the, what is that, the score thing, that&#39;s all lit up, like it looks really cool.</p>
                            <p>Bryan White (01:18:41.296)
                            The scoreboard lights up, yeah.</p>
                            <p>Bryan White (01:18:45.472)
                            It&#39;s a very well composed shot. Like if I got anything good to say about this movie, one, Radish, he&#39;s the best. Two, Wild Man&#39;s death scene. Because the composition is shy.</p>
                            <p>Dave (01:18:55.758)
                            Because this is the one death scene that&#39;s like, wow, this is a little bit, this is kind of gnarly.</p>
                            <p>Bryan White (01:18:59.404)
                            Yep. So he starts to like walk away and then the lights come up in the place and the killer is standing there in the middle and like center court, but like in silhouette. And then they struggle like like wild man goes at him and the killer just dispatches him very easily just whips the shit out of him.</p>
                            <p>Dave (01:19:16.502)
                            Which is a little bit weird because Wildman is a, he&#39;s a big dude. And we are sort of given to believe that he is a, has some sort of physical prowess when it comes to fighting.</p>
                            <p>Bryan White (01:19:20.76)
                            Yeah, yeah.</p>
                            <p>Bryan White (01:19:27.372)
                            Yeah, so they struggle for a while. They end up like crashing into the weight room where the killer gets the best of him and he wraps the cables of essentially like a cable pole machine, like a weight machine around his neck. And then, you know, you see him lift like 200 pounds of plates and then it&#39;s, you know, it snaps him back and he gets choked out. Apparently that actor got really choked out in that scene.</p>
                            <p>Dave (01:19:52.462)
                            Yeah, we hardly knew ye. Yeah, cuz it&#39;s not gonna fucking matter if you see him or don&#39;t.</p>
                            <p>Bryan White (01:19:53.632)
                            Yep. Yep. But as they&#39;re happening, also there are like profile shots of the killer, like they&#39;re not hiding this guy anymore.</p>
                            <p>Bryan White (01:20:06.872)
                            He&#39;s just like a guy in like a green army jacket with a shitty haircut. And then, like there&#39;s a couple of times where like they just straight up show his face. It was...</p>
                            <p>Dave (01:20:17.782)
                            And you know what he looks like every, every time they show him, you know what he looks like is he looks like the scene in sleep away camp where they, right before Judy&#39;s death, where they try to make you think that, well, I don&#39;t want to ruin sleep away camp everybody, but like, hey fuck yeah we are. And, but they try to make you think it&#39;s, it&#39;s somebody else in the shot. And so it&#39;s like, this kind of like, looks like a figure with a really bad mullet wig.</p>
                            <p>Bryan White (01:20:32.408)
                            We&#39;re doing sleepaway camp.</p>
                            <p>Bryan White (01:20:46.188)
                            Yeah.</p>
                            <p>Dave (01:20:47.018)
                            And like, that&#39;s the way it looks every time they show it. It&#39;s like, it looks like someone just placed a really awful, do you know who Peter Berlin is? You probably don&#39;t. He was a, he&#39;s sort of a gay icon from the 70s. He was kind of a Castro clone. These things don&#39;t mean anything to you. He looked like He-Man.</p>
                            <p>Bryan White (01:20:56.368)
                            doesn&#39;t ring a bell.</p>
                            <p>Dave (01:21:09.59)
                            He really looks exactly like he man. It looks like someone took this awful kind of like Dutch boy hair and placed it on his head. That&#39;s what this, every time they show this silhouette, it&#39;s like, it looks like someone took a really awful Stephen King wig and just placed it right on his head.</p>
                            <p>Bryan White (01:21:09.613)
                            Ha ha</p>
                            <p>Bryan White (01:21:15.3)
                            Yeah.</p>
                            <p>Bryan White (01:21:23.288)
                            Oh, see, I can never remember the name of the actor off the top of my head. The bad guy in No Country for Old Men is what I kept thinking of.</p>
                            <p>Dave (01:21:31.714)
                            The character&#39;s Anton Chigurh, I believe. Javier Bardem.</p>
                            <p>Bryan White (01:21:34.048)
                            Yeah, yeah, that character, but I can never remember the name. Yeah, that&#39;s him. That&#39;s, yeah, that&#39;s him. So now Radish shows up, because one of the things that we did, we completely glossed over in the beginning, is the coach gave him an imperative. He&#39;s like the fucking equipment manager or something like that in the gym. So Radish has to go there to count the equipment or something. I don&#39;t know.</p>
                            <p>Dave (01:21:46.535)
                            Oh man.</p>
                            <p>Dave (01:22:00.99)
                            Yeah, but he can&#39;t cuz he&#39;s trashed.</p>
                            <p>Bryan White (01:22:05.032)
                            Is this after the drinking whiskey?</p>
                            <p>Dave (01:22:07.414)
                            He doesn&#39;t, so he shows up in her room first, before, so he&#39;s drunk and he shows up at What&#39;s-Her-Face&#39;s dorm room. And this is when they start to give that impression that like he&#39;s interested in her. And this is the, I don&#39;t remember any of their fucking names. If you&#39;re not Radish, I don&#39;t care who you are. So he shows up at a final girl&#39;s room and he&#39;s like kind of giving her that like, you have a real pretty face, or whatever the fuck he says.</p>
                            <p>Bryan White (01:22:10.818)
                            Yeah.</p>
                            <p>Bryan White (01:22:15.256)
                            Yeah.</p>
                            <p>Bryan White (01:22:25.964)
                            Yeah. Yeah.</p>
                            <p>Bryan White (01:22:35.148)
                            Oh, that&#39;s right. Is that before this? Yeah, it must have.</p>
                            <p>Dave (01:22:38.794)
                            Yes. Because then he goes to the thing and finds the dead body.</p>
                            <p>Bryan White (01:22:43.988)
                            Yeah, so because also like he basically he basically goes to a room and like kind of tells her that he&#39;s into her.</p>
                            <p>Dave (01:22:50.338)
                            but kinda doesn&#39;t at the same time. He sort of, it would be like if I went to a room, it was just like, hey, you know, chin up, buckaroo. You got a real pretty face. Like, I don&#39;t fucking know.</p>
                            <p>Bryan White (01:23:01.016)
                            Yeah, but he walks away and he&#39;s like that was real stupid kid like he&#39;s talking to himself really loudly</p>
                            <p>Dave (01:23:04.726)
                            Now...</p>
                            <p>Dave (01:23:07.83)
                            Look, I don&#39;t want to sound like an essentialist here, but the fact that they want us to believe that radish is a straight carrot.</p>
                            <p>Dave (01:23:19.666)
                            It&#39;s a strange credulity because...</p>
                            <p>Bryan White (01:23:22.304)
                            It&#39;s a reach in this movie because his performance is... It&#39;s... He&#39;s very effeminate and it&#39;s very weird when he tries to sort of do the scene with Courtney because just the whole movie, I&#39;m like, is he gay? Like, is this what the character, is this what he&#39;s doing? Like, what is this?</p>
                            <p>Dave (01:23:26.57)
                            Again, boys in the band, like he is really giving you flamboyant gay character.</p>
                            <p>Dave (01:23:47.198)
                            And so this is this is that sort of hangover from that idea of like, what is a pansy in a in like a fifties and sixties character? Like it&#39;s that idea of like, oh, you know, you&#39;re kind of a mincing, you know, you&#39;re a teacher&#39;s pet. You&#39;re really studious and all that. And at some point, it kind of blur like the line blurs where it&#39;s like, you&#39;re not giving us that anymore. You&#39;re just like I.</p>
                            <p>Bryan White (01:23:53.872)
                            Yeah, yeah.</p>
                            <p>Bryan White (01:24:12.064)
                            He&#39;s just, it&#39;s... I wonder if there&#39;s a word that everybody would want to know. Ha ha ha ha ha ha!</p>
                            <p>Dave (01:24:12.89)
                            I want to use a word that everybody would bristle at, unless you were in my living room, and even then you might, but it&#39;s just, he&#39;s so gay. And we&#39;re supposed to believe that he&#39;s just like, no, no, I just, I just told her the truth, and well, now she knows. And he walks away, and when he says that line of like, well, I can&#39;t believe you did it, that was stupid, kid. You get the feeling, like there is about to be a big swell of music, and he is going to burst into song.</p>
                            <p>Bryan White (01:24:21.132)
                            Yeah. Yeah.</p>
                            <p>Bryan White (01:24:42.288)
                            Hahaha!</p>
                            <p>Dave (01:24:42.358)
                            Like it has that kind of like fucking musical moment and it&#39;s like, who are you selling this to? Nobody believes that.</p>
                            <p>Bryan White (01:24:50.412)
                            Yeah. Yeah. But he, um, he also lays one of his sort of like patented lines on her where she&#39;s like, you don&#39;t, yeah. It&#39;s like she he&#39;s saying all this like morbid shit to her and she&#39;s like, you don&#39;t have to be so morbid all the time. And he&#39;s like, people are killed every day for no reason at all. Which is another really great line. But now, now it&#39;s Mark&#39;s turn to die. Finally.</p>
                            <p>Dave (01:24:59.462)
                            at one of those patented radish lines.</p>
                            <p>Bryan White (01:25:22.213)
                            He goes in after, I don&#39;t know, a wild man doesn&#39;t show up, so he goes to look for him, and he finds him stuffed into a locker, which is ironic. But he&#39;s also upside down, which is awesome.</p>
                            <p>Dave (01:25:33.226)
                            Yeah, I thought the bar was pretty cool.</p>
                            <p>Bryan White (01:25:34.796)
                            Yeah, and he gets chased to like a boiler room or like electrical room on the campus and the killers, you know, he keeps thinking he&#39;s given the killer to slip. It&#39;s like a really fucking long chase scene. And then he basically turns and runs directly into the killer&#39;s knife, which he gets stabbed through with. And then we cut.</p>
                            <p>Dave (01:25:54.823)
                            And here&#39;s what the movie doesn&#39;t understand that I think maybe a lot of movies in the 80s didn&#39;t really get is there are two ways that this can go if you want to be effective. One is we either have to care about this person a lot when they die. It doesn&#39;t have to be big. Like it&#39;s like when Nancy Kai&#39;s character in Halloween. What the fuck is her name? Annie.</p>
                            <p>Bryan White (01:26:06.68)
                            Yeah.</p>
                            <p>Bryan White (01:26:18.531)
                            Yeah.</p>
                            <p>Dave (01:26:19.03)
                            When she dies in the car, and you&#39;re like, and that scene is long and horrible, but it&#39;s like, we don&#39;t want those characters to die. So when they do die, it sucks. Or it has to be, you know, thrown into a sleeping bag and slammed against a tree. Like, it has to be either really big or it has to really suck. I don&#39;t give a fuck about this guy. I don&#39;t care how he dies.</p>
                            <p>Bryan White (01:26:27.245)
                            Yeah.</p>
                            <p>Bryan White (01:26:33.355)
                            Right, right.</p>
                            <p>Bryan White (01:26:37.68)
                            That&#39;s the thing.</p>
                            <p>Bryan White (01:26:42.24)
                            Right, I mean, it would have been great, or it would have been at least better if it had been some sort of elaborate set piece, because he&#39;s such a despicable character. Nothing about him is ever good. I hated him every time he was on screen, and I kept thinking, oh, his death scene is going to be cathartic.</p>
                            <p>Dave (01:27:01.454)
                            Because that&#39;s what that is. When they build up these characters, they&#39;re going to die, so like, we want you to hate them so when they die, there&#39;s payoff. There&#39;s no fucking payoff to any of these people because they&#39;re not set pieces, they just sort of die. Also, we skipped over the fact that when he&#39;s, when Radish is in the room, the dorm room, there&#39;s a photograph of the chemistry professor, just like framed and on the dresser, but it&#39;s like clearly, it&#39;s clearly a headshot.</p>
                            <p>Bryan White (01:27:08.931)
                            Yeah.</p>
                            <p>Bryan White (01:27:14.508)
                            Yeah, yeah. And so.</p>
                            <p>Bryan White (01:27:29.994)
                            Yeah, oh it&#39;s like his professional headshot. Yeah, so yeah, no, no, no, I actually jumped over this So what I meant would radish now he shows up to the to the gym To count the equipment. He finds the front door open and he says to himself Huh? Shades of Watergate and all I could think of did you did you watch you watch true detective?</p>
                            <p>Dave (01:27:32.236)
                            Yeah.</p>
                            <p>Dave (01:27:55.398)
                            first season.</p>
                            <p>Bryan White (01:27:56.372)
                            Okay, so the first season, there is a scene where Cole and, I can&#39;t remember, Marty, they&#39;re going back to his car at some point and Cole is saying just Rustin&#39; Cole shit. And Marty says, I want you to stop saying odd shit. That&#39;s all I could think of in this scene because he just keeps muttering these like weird little non-sequiturs and like strange just bonmos that go nowhere.</p>
                            <p>Dave (01:28:21.73)
                            Except like True Detective is like, that has a lot of satire in it. This does not. This movie has no self-awareness at all. Which is its only saving grace.</p>
                            <p>Bryan White (01:28:29.388)
                            Yep. Yep. So, yeah, so Rannish, as he&#39;s walking, you can fucking, they must have miked his pants, because you can hear, you can hear the corduroy as he&#39;s walking. And this is when he goes in and he finds Mark, Mark&#39;s dead body falls out of a locker, and he goes and he finds the phone and he calls the sheriff again.</p>
                            <p>Dave (01:28:41.838)
                            Because you know those are quarter eyes. That&#39;s a whale cord.</p>
                            <p>Bryan White (01:28:57.956)
                            The sheriff gets on the line and he says, it&#39;s happening. It&#39;s happening here. And so once again, fuck this sheriff. Do your stupid job, pig, because all this guy does is fight with Radish on the phone before he just hangs up. And now we see Radish run again. So now it&#39;s time. Oh, god, now I see it. So now it&#39;s Karen or B.</p>
                            <p>Dave (01:29:08.162)
                            Ugh.</p>
                            <p>Bryan White (01:29:25.976)
                            janitor lisa whatever her name is it&#39;s her turn to die she&#39;s the one who&#39;s supposedly up in the the conservatory waiting for the teacher to show up yes</p>
                            <p>Dave (01:29:32.56)
                            She&#39;s this is where she&#39;s in the Gothic Cathedral. She is she this is like it&#39;s like something out of dark shadows. I don&#39;t know where she is. She seemed like some attic of a castle somewhere.</p>
                            <p>Bryan White (01:29:41.572)
                            She&#39;s in a-</p>
                            <p>Bryan White (01:29:44.352)
                            She&#39;s in a sexy, like, plunging dress, playing piano in the dark, or by candlelight or something. Um, and again...</p>
                            <p>Dave (01:29:53.006)
                            Did you ever see the night Evelyn came home from the grave or came out of the grave or whatever? There&#39;s a scene in that where a lot of it takes place in the attic and this is an Italian giallo that is pretty atmospheric. And it&#39;s like that, it&#39;s like she&#39;s in the attic of some fucking castle doing art.</p>
                            <p>Bryan White (01:29:56.602)
                            Yeah. Yeah.</p>
                            <p>Bryan White (01:30:11.734)
                            I mean, where else are you going to do art?</p>
                            <p>Dave (01:30:14.866)
                            at some weird college in the south.</p>
                            <p>Bryan White (01:30:17.46)
                            Yeah, but again this is this is we go back to to when Janet died we go back to that that ruse again and they it&#39;s not just it&#39;s she&#39;s Gary&#39;s girlfriend the one who finds the trail of clothes this this isn&#39;t just in this movie this happens in a lot of slasher movies and like you pointed out a couple as we were talking about it what when she&#39;s when a victim is waiting for someone and the killer is nearby</p>
                            <p>Dave (01:30:28.194)
                            Which one is Janet?</p>
                            <p>Dave (01:30:32.643)
                            Okay.</p>
                            <p>Bryan White (01:30:45.072)
                            they&#39;re in the room or they&#39;re real close, they call out to whoever it is that they&#39;re expecting, and then they keep doing it even though they get no reply, and for whatever reason the character in the movie automatically thinks that their boyfriend or whatever is playing games with them, and</p>
                            <p>Dave (01:31:00.662)
                            It&#39;s that scene from Black Christmas where she&#39;s like, who is that? Who&#39;s in there? And it&#39;s like, why are you talking to the fucking closet?</p>
                            <p>Bryan White (01:31:04.045)
                            Yeah.</p>
                            <p>Bryan White (01:31:07.648)
                            Yeah, like they&#39;re setting themselves up to be murdered. If I were alone in the dark and I called out to the person, to somebody and the person I was expecting didn&#39;t reply, I would get the fuck out of there, I would lock the door, I would, yeah, yeah, this is not cute. Because eventually, like inevitably what ends up happening is it turns from like amused to like scared where they&#39;re like, this isn&#39;t funny anymore, like that kind of thing, that doesn&#39;t happen here. But like she&#39;s very confused.</p>
                            <p>Dave (01:31:17.93)
                            You have five seconds or I&#39;m leaving.</p>
                            <p>Bryan White (01:31:36.564)
                            And we cut away, we cut back to Radish, hauling ass, running so fucking fast. He is practically on fire. But as he&#39;s running.</p>
                            <p>Dave (01:31:44.17)
                            And this is a cartoonish goofy run.</p>
                            <p>Bryan White (01:31:47.404)
                            because also the music has this like xylophone sound that&#39;s like Looney Tunes shit where it&#39;s like the mallet goes up the xylophone and then goes down the xylophone. It&#39;s like.</p>
                            <p>Dave (01:31:57.718)
                            Yeah, you may have, you may as well have played some real circus music here.</p>
                            <p>Bryan White (01:32:01.992)
                            But he is running so fucking fast. The fastest slasher movie character I&#39;ve ever seen. He runs twice in this movie and he also has a fucking funny run, but also not fast enough. So we cut back to what&#39;s her name, the sexy one for a little cheap nudity, which surprised me. No, it doesn&#39;t fucking matter. I don&#39;t care. But a little cheap nudity, which is really wild because this movie is super chased. Like it&#39;s, like I said.</p>
                            <p>Dave (01:32:10.734)
                            but not fast enough.</p>
                            <p>Dave (01:32:19.31)
                            Doesn&#39;t matter. Doesn&#39;t matter what her name is.</p>
                            <p>Bryan White (01:32:29.452)
                            It&#39;s barely rated R, but this is obviously, you know, seeing that it just kind of nails it down. Like if they were gonna get an R rating at all, it was for this because the violence in the movie is hardly there at all. All the time that Radish is running, I&#39;m like, you better make it to the end of this fucking movie or I&#39;m gonna be so mad.</p>
                            <p>Dave (01:32:49.658)
                            He radishes our antebracket. You don&#39;t want him to die.</p>
                            <p>Bryan White (01:32:54.3)
                            No, no, he&#39;s the only one that I cared about and I was so mad because he gets to Courtney&#39;s door and he knocks on it and the killer punches through and pulls his head in and I don&#39;t know what he does to him because we don&#39;t see it but fuck, radish is dead. We go, he somehow makes it back to Lisa&#39;s alcove wherever it is that she is, this gothic cathedral that she&#39;s playing piano in.</p>
                            <p>Bryan White (01:33:21.724)
                            She&#39;s surrounded by paintings and stuff like that, because it&#39;s like an art studio.</p>
                            <p>Dave (01:33:26.006)
                            I mean, she&#39;s playing piano, she&#39;s playing a grand piano in the attic of God knows where. And then when she&#39;s done with that, she&#39;s going to do some portrait painting. Like, what is this? Who are you? Get out. God.</p>
                            <p>Bryan White (01:33:40.088)
                            It&#39;s a loft in the East Village. But yeah, so the killer somehow makes it over there very, very fast. And she, again, she thinks it&#39;s the teacher who&#39;s there, and so she&#39;s like nude and then like wraps herself in like a silver blanket or something. One of those like space blankets they give you when you do a marathon. And she gets stabbed by the killer. And in a little bit of not so subtle moralizing like the</p>
                            <p>Bryan White (01:34:07.14)
                            blood spatters on all of these like paintings of nudes. And I, this part kind of, this shit drives me fucking nuts. Even though it&#39;s like rote slasher movie stuff. John Carpenter has been very clear over the years that there was not, there wasn&#39;t any moralizing in Halloween, like all the shit that people heaped on it later that like Laurie Strode is.</p>
                            <p>Dave (01:34:26.667)
                            Oh, don&#39;t get me started.</p>
                            <p>Bryan White (01:34:28.184)
                            that like Laurie Strode is this like chased good girl. Like it&#39;s all just, it was fucking happenstance. Like we just didn&#39;t see her on any given day in her life. She could have been like PJ Soles&#39; character or something like that. Like we just don&#39;t fucking know. Like she wasn&#39;t rewarded for, you know, not having sex or something like that. This movie makes that equivalency, bothering the shit out of me. Cause you know, it always does when I see that stuff in those movies. Yeah, yeah. But also like this is now the movie has been like playing</p>
                            <p>Dave (01:34:51.446)
                            Oh, because everybody misses that point.</p>
                            <p>Bryan White (01:34:57.988)
                            basically a uh like a russ halloween theme ripoff over this that&#39;s actually not half bad it&#39;s the same sort of five four time signature thing that like halloween does</p>
                            <p>Dave (01:35:08.746)
                            It doesn&#39;t work the same way, but it is the one moment where I was just like, okay, you&#39;re doing a thing.</p>
                            <p>Bryan White (01:35:13.312)
                            Yeah, yeah, because like, I mean, honestly, like the music coincides with the things are kind of getting a little tense. But.</p>
                            <p>Dave (01:35:19.102)
                            Anything is better than the music from Full House.</p>
                            <p>Bryan White (01:35:23.069)
                            Yep So now it&#39;s time for a payoff to check off stray elevator because court</p>
                            <p>Dave (01:35:29.503)
                            My note actually says, oh my god, it was Chekhov&#39;s lunch tray conveyor belt.</p>
                            <p>Bryan White (01:35:35.672)
                            Yep, so now he&#39;s chasing Courtney around because she goes and she finds Radish&#39;s body and she runs across the quad ends up in the kitchen and she manages to sort of like give the killer the slip and ends up down basically the dish pit. And what you see is his feet.</p>
                            <p>Dave (01:35:53.87)
                            Because she finds the killer and the killer, the big reveal is that the killer is nobody. Now, look, I think that the killer being nobody is a thing that can work really, really well. But you have to give us a little bit more than that.</p>
                            <p>Bryan White (01:35:59.288)
                            He&#39;s just some dude.</p>
                            <p>Bryan White (01:36:10.22)
                            Yeah, I mean, if it&#39;s perfectly random and he&#39;s just a nobody, that can work if the rest of the movie is kind of pulling to give that meaning.</p>
                            <p>Dave (01:36:21.75)
                            the whole point of Michael Myers&#39; character is that the reason he&#39;s called the Shape is because he is nobody. He is nothing. He is a menace that has nothing.</p>
                            <p>Bryan White (01:36:26.912)
                            Yeah, yeah, like the the the the mask is a white featureless face for a fucking reason.</p>
                            <p>Dave (01:36:34.186)
                            Same thing in Black Christmas. You have no idea who this killer is, and it doesn&#39;t matter. It never matters who it is.</p>
                            <p>Bryan White (01:36:36.9)
                            That&#39;s right, that&#39;s right. You see his eyeball, you never see his face. His identity is...</p>
                            <p>Dave (01:36:44.682)
                            And that&#39;s why he&#39;s creepy and frightening because he is nothing. He is no, he could be anybody, but.</p>
                            <p>Bryan White (01:36:48.3)
                            Yeah. So there you go. Two examples where the anonymous identityless killer works. Does not happen here. Yeah. Yeah. But yeah, so the killer rides the tray elevator down. Because you see, you don&#39;t see the rest of his body. You see his feet and his legs on it. And then he takes a step forward, and the camera cuts away, obviously, because here&#39;s the thing. The little hole.</p>
                            <p>Dave (01:36:56.458)
                            Because those movies are made by talented people.</p>
                            <p>Bryan White (01:37:15.844)
                            that the trays come out of is like 12 feet by 12 feet. Somehow, yeah, somehow this guy squeezes his fucking ass through this thing in order to resume the chase. And so at one point she sort of gives him the slip and gets like, yeah, so she sneaks up behind him and like hits him with a ladle or something.</p>
                            <p>Dave (01:37:19.505)
                            If that.</p>
                            <p>Dave (01:37:35.274)
                            Yeah, cause he&#39;s stuck in the conveyor belt.</p>
                            <p>Bryan White (01:37:43.092)
                            and throws him into a walk-in freezer and locks the door behind him. And then as she sort of like, oh, like slowly walks away from him, he comes bursting out of another door, like halfway down the hallway, like the hallway chase scene from fucking Scooby-Doo or something. And so he chases her into that sort of condemned area from beginning. And again, go back and again. It&#39;s, yeah. I see it, roughly. This building.</p>
                            <p>Dave (01:38:06.865)
                            And again, we&#39;re back at a Gothic cathedral. Why do they have crumbling buildings? This is like a clock tower from the 18th century.</p>
                            <p>Bryan White (01:38:15.404)
                            Yeah, this building is perilous. And so he chases her all the way up to the top of the thing, and he&#39;s got her. And this is where, OK, I have the note. I thought it was the sheriff. It&#39;s not the sheriff. It&#39;s the fucking coach. And he shows up. Now, this explains why he has a bow and arrow, and he&#39;s not a gun if he&#39;s not the sheriff. He shows up with a bow and arrow. And he shows up with a bow and arrow.</p>
                            <p>Dave (01:38:37.238)
                            Because he and the guy are supposed to go hunting.</p>
                            <p>Bryan White (01:38:40.08)
                            supposed to go hunting and he comes into the he she&#39;s screaming out the window for her for him to come and help her and he does he comes running in and he goes up a couple of</p>
                            <p>Dave (01:38:49.206)
                            Yeah, but at no point does this guy ever move with any sense of urgency.</p>
                            <p>Bryan White (01:38:53.08)
                            Nope, nope, he&#39;s just keeping a medium pace. And he comes in and he takes aim with the arrow at the killer who is just fully revealed now. Like we said, some dude, and he&#39;s like, I&#39;m gonna hang your head on my wall, which is kind of a hell of a threat. And he fires the arrow at him and at the killer. And you have to take these making of stories with a bit of a grain of salt. But so the story goes, this arrow catch was real.</p>
                            <p>Bryan White (01:39:20.088)
                            where the guy playing the killer was like some sort of a martial artist or something like that and the arrow catch was like a trick that he did at like exhibitions and shit. So that was, I watched it a couple of times and he definitely grabs a flying arrow. And so he turns and he runs down and he kills the coach. Thank God with the arrow that he just he just fired at him. And you know, of course the coach.</p>
                            <p>Dave (01:39:29.349)
                            It&#39;s it is swift and impressive.</p>
                            <p>Bryan White (01:39:45.552)
                            collapses but this gives Courtney a chance he comes basically stalking back in Courtney she grabs like a loose board or something and hits him hits the killer which knocks the knife out of his hand for what is the longest tracking shot of the knife falling</p>
                            <p>Dave (01:40:01.526)
                            Yeah, no, this whole scene, his whole death scene is the most protracted death scene I&#39;ve ever seen in any movie.</p>
                            <p>Bryan White (01:40:08.18)
                            I counted the shots, there are six cuts as the knife falls and hits the floor. There&#39;s six. The hit cut made it...</p>
                            <p>Dave (01:40:13.326)
                            Alfred Hitchcock made an entire movie out of six shots. They made a ten minute death scene of it.</p>
                            <p>Bryan White (01:40:16.688)
                            Yeah. They made it 10 minutes into the game. Yeah. And so she hits him. He gets his, so again, Chekov&#39;s perilous condemned building. Like he gets his leg, he like his leg falls through one of these broken boards and it gives her enough time to just beat the fuck out of him. And he sort of like tries to climb out onto like a scaffolding and he ends up falling. And again, longest tracking shot of the killer falling. I counted it. Five fucking cuts of him before he hits the bottom.</p>
                            <p>Dave (01:40:44.894)
                            They got to that scene and they were like, well, we still have 112 feet of film left. What do we do?</p>
                            <p>Bryan White (01:40:47.565)
                            If we- if we-</p>
                            <p>Bryan White (01:40:51.316)
                            Let&#39;s see we paid for it. We might as well use it. So she you know relieve You know the kind of music of relief comes as she walks down to the bottom We get the requisite killers second chance where he grabs her Leg and she grabs the knife and we don&#39;t actually see her stab him, but we see her stabbing a Lot. This is a brutal brutal</p>
                            <p>Dave (01:41:13.526)
                            Yeah, she stabs the shit out of him.</p>
                            <p>Bryan White (01:41:16.464)
                            brutal death scene where and there&#39;s something so primal about the way she&#39;s doing it she must have been like sore from stabbing him and uh somehow the MPAA took umbrage with this scene and gave the movie based on this scene alone gave the movie an x rating so if this is even true which i don&#39;t i don&#39;t know it sounds very far fetched</p>
                            <p>Dave (01:41:37.07)
                            The MPAA doesn&#39;t give a shit about this movie.</p>
                            <p>Bryan White (01:41:40.532)
                            It makes me think that the MPAA just had it out for slasher movies, which they did. Like there&#39;s a lot of movies that like since then have been restored to like director&#39;s cuts on DVD and Blu-ray, and they are remarkably more violent than the ones that ran theatrically. Yeah. But like, just even without, like, cause without this particular scene, this is a very, very tame 80s slasher. But apparently she stabbed him 18 times in the original cut.</p>
                            <p>Dave (01:41:54.262)
                            I mean, Friday too is shocking.</p>
                            <p>Bryan White (01:42:10.04)
                            and they cut it down to 12 and managed to slide past with an R rating. So the killer is definitively dead after being like gouged with this fucking gigantic knife and she goes in the credits roll. And I hope that everybody named in this credits list lives with a terrible shame for making this movie.</p>
                            <p>Dave (01:42:28.558)
                            They all pretend they had nothing to do with it.</p>
                            <p>Bryan White (01:42:31.34)
                            Wasn&#39;t there. Yep, the end no explanation offered. No identity of the killer is revealed Even the sleazy just even the the laziest slasher movie cash-ins give you something to work with this one doesn&#39;t even bother so</p>
                            <p>Dave (01:42:46.09)
                            Yeah, this movie is utter garbage except for radish. It is worth watching because of that, because it is so weird.</p>
                            <p>Bryan White (01:42:48.948)
                            except for Radish. Because of Radish, because of Radish you should watch this movie. Otherwise, man, there&#39;s got to be something else that you gotta watch. Even if you&#39;ve watched everything else on Shudder, watch something else. Watch The Final Terror, that&#39;s on Shudder, it&#39;s great. Final Exam, I don&#39;t think so.</p>
                            <p>Dave (01:43:03.275)
                            And I have.</p>
                            <p>Dave (01:43:12.258)
                            Well, that was our, that was movie one of our theme month, our Schools Out theme month.</p>
                            <p>Bryan White (01:43:16.656)
                            Schools out. Yep. Schools out, sir. It continues next week with what?</p>
                            <p>Dave (01:43:22.102)
                            with a criminally underrated and overlooked picture that I love that is called House on Sorority Row.</p>
                            <p>Bryan White (01:43:30.864)
                            All right, this is gonna be interesting because I have never seen this one.</p>
                            <p>Dave (01:43:33.674)
                            I fucking love this movie, and it is not at all what you think it is. Because the poster art and the cover art really did this movie dirty.</p>
                            <p>Bryan White (01:43:37.91)
                            Yeah. Yeah.</p>
                            <p>Bryan White (01:43:47.012)
                            Alright, I&#39;m looking forward to it. So we&#39;ll see you back here in two weeks for the House on Sorority Row.</p>
                            <p>Dave (01:43:47.633)
                            It is not what you think.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-howling">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)